export const ROUTES = {
  ROOT: '/',
  LIST_OF_CLASSES: '/list-of-classes',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGET_PASSCODE: '/forget-passcode',
  ACCOUNTS: '/accounts',
  TERMS_AND_CONDITION: '/terms-and-conditions',
  CLASSROOM_INVITE: '/class/join/:id',
  PRACTICE_CLASS_QRCODE_CAPTURE: '/practice-evaluations',
};

export const PARENT_PATHS = {
  PARENT_DASHBOARD: '/p/home/:studentId',
  ALLCLASSES: '/p/classes/:studentId',
  PRACTICE_SHEET: '/p/practice/:studentId',
  CLASS_OVER: '/p/:studentId/class/:classId',
  REPORTS_MENU: '/p/reports/:studentId',
  REPORTS: '/p/reports/:studentId/chapter',
  QUESTION_CATEGORY: '/p/reports/:studentId/chapter/:chapterId/understanding',
  CHAPTER_LEVEL_UNDERSTANDING:
    '/p/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel',
  REPORTS_STEP_LEVEL:
    '/p/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel/:categoryId',
};

export const STUDENT_PATHS = {
  STUDENT_DASHBOARD: '/s/home',
  STUDENT_CLASSES: '/s/classes',
  STUDENT_PRACTICE: '/s/practice',
  CLASS_NOTES: '/s/class-notes',
};

export const ADMIN_PATHS = {
  ADMIN_REPORTS_MENU: '/a/reports/:studentId',
  ADMIN_REPORTS: '/a/reports/:studentId/chapter',
  ADMIN_QUESTION_CATEGORY: '/a/reports/:studentId/chapter/:chapterId/understanding',
  ADMIN_CHAPTER_LEVEL_UNDERSTANDING:
    '/a/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel',
  ADMIN_REPORTS_STEP_LEVEL:
    '/a/reports/:studentId/chapter/:chapterId/understanding/category/:understandingLevel/:categoryId',
};
