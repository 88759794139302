import { Action, ThunkAction, combineSlices, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  PersistConfig,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import apiSlice from './apiSlices';
import { studentRegisterSlice } from './slice/studentRegister.slice';
import { networkIssueCounter } from './slice/networkIssueCounter.slice';
import { classNotesState } from './slice/classNotes.slice';
import { inClassConfigSlice } from './slice/inClassConfig.slice';
import { contentInfo } from './slice/content.slice';
import { multiLecture } from './slice/lecture.slice';
import { reportsData } from './slice/reports.slice';

// !Add slices here
const slices = [
  studentRegisterSlice,
  networkIssueCounter,
  classNotesState,
  contentInfo,
  inClassConfigSlice,
  multiLecture,
  reportsData,
];

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
// ?Need to mention all the slices here
const rootReducer = combineSlices(...slices, apiSlice);

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>;

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'studentRegister',
    'counterSlice',
    'networkIssueCounter',
    'classNotesState',
    'inClassConfigSlice',
    'contentInfo',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeStore = (preloadedState: any) => {
  const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(apiSlice.middleware),
    preloadedState,
  });
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  const persistor = persistStore(store);

  setupListeners(store.dispatch);
  return { store, persistor };
};

export const { store, persistor } = makeStore({});

// Infer the type of `store`
export type AppStore = typeof store;
// Infer the `AppDispatch` type from the store itself
export type AppPersistor = typeof persistor;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;
