import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { CONFIG } from 'configs';
import { useInclassLogger, useMeeting, useSocketSubscribe } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  selectHtmlLink,
  selectHtmlSlide,
  selectHtmlStep,
  selectTutorRoomChange,
  setTutorRoomChange,
} from 'store/slice/content.slice';
import {
  selectMyCurrentState,
  selectRequestContentValidation,
  setRequestContentValidation,
} from 'store/slice/inClassConfig.slice';
import { FileMessagePayload, StateKeys } from 'types';
import './style.css';
import { constructContentLink } from 'utils/inClass/contentLink';
import { SocketContext } from 'contexts';

const Content = () => {
  // States
  const [htmlLink, setHtmlLink] = useState<string>('');
  const [isJumpAnimationComplete, setIsJumpAnimationComplete] = useState<boolean>(false);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  // Get socket from context
  const socket = useContext(SocketContext);

  // State to store current step and slide from iframe
  const [currentStepSlide, setCurrentStepSlide] = useState<{
    step: number;
    slide: number;
  } | null>(null);

  // Ref
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Redux selectors
  const getHtmlLink = useAppSelector(selectHtmlLink);
  const getHtmlSlide = useAppSelector(selectHtmlSlide);
  const getHtmlStep = useAppSelector(selectHtmlStep);
  const myCurrentState = useAppSelector(selectMyCurrentState) || CONFIG.ROOMS.TEACH;
  const tutorRoomChange = useAppSelector(selectTutorRoomChange) || false;
  const requestContentValidation = useAppSelector(selectRequestContentValidation);

  // Hooks
  const { studentName, classId, studentId } = useMeeting();
  const { datadogLog } = useInclassLogger();

  const { meeting, tutorId } = useMeeting();

  const takeScreenshotHandlerContentOnly = useCallback(
    (data: { studentId: string; classId: string }) => {
      if (String(studentId) !== data.studentId || String(classId) !== data.classId) return;
      iframeRef?.current?.contentWindow?.postMessage(
        {
          type: 'uprio_custom',
          fn: 'take_screenshot',
        },
        '*',
      );
    },
    [classId, studentId],
  );

  useSocketSubscribe<{ studentId: string; classId: string }>(
    'takeScreenshotToClient',
    takeScreenshotHandlerContentOnly,
  );

  // Helper function to send 'JumpToAnim' postMessage
  const sendJumpToAnimMessage = useCallback(
    (step: number, slide: number) => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          {
            type: 'uprio_custom',
            fn: 'JumpToAnim',
            params: [step, slide, true],
          },
          '*',
        );
      }
    },
    [iframeRef],
  );

  // Effect to update htmlLink when state changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHtmlLink(getHtmlLink);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [getHtmlLink, myCurrentState]);

  // Send JumpToAnim message when slide or step changes
  useEffect(() => {
    sendJumpToAnimMessage(getHtmlStep, getHtmlSlide);
    dispatch(setTutorRoomChange(false));
  }, [
    getHtmlSlide,
    getHtmlStep,
    sendJumpToAnimMessage,
    myCurrentState,
    tutorRoomChange,
    dispatch,
    requestContentValidation,
  ]); // added tutorRoomChange to render on tutor Room change

  // Socket handler for htmlSlidesEveToClient event
  const htmlSlidesHandler = useCallback(
    ({
      htmlLink,
      currentState,
      slide,
      step,
      studentID,
    }: {
      htmlLink: {
        teach: string;
        coach_basic: string;
        coach_intermediate: string;
        coach_advanced: string;
      };
      slide: number;
      step: number;
      currentState: StateKeys;
      studentID: number;
    }) => {
      if (studentID && studentID !== studentId) return;
      if (currentState === myCurrentState) {
        if (step === 0) {
          sendJumpToAnimMessage(step, slide);
        }
        const contentLink = htmlLink?.[myCurrentState];
        setHtmlLink(contentLink);
        datadogLog(`Changing content for ${studentName}`);
      }
    },
    [studentId, myCurrentState, sendJumpToAnimMessage, datadogLog, studentName],
  );

  // Callback to emit content validation event
  const emitContentValidationEvent = useCallback(() => {
    if (requestContentValidation) {
      socket?.emit('contentValidation', {
        classId,
        studentId,
        htmlStep: currentStepSlide?.step,
        htmlSlide: currentStepSlide?.slide,
        currentState: myCurrentState,
      });

      setCurrentStepSlide(null);
      // Reset the validation request flag
      dispatch(setRequestContentValidation(false));
    }
  }, [
    requestContentValidation,
    currentStepSlide,
    socket,
    dispatch,
    classId,
    studentId,
    myCurrentState,
  ]);

  // Effect to trigger content validation
  useEffect(() => {
    if (currentStepSlide) {
      emitContentValidationEvent();
    }
  }, [requestContentValidation, currentStepSlide, emitContentValidationEvent]);

  useSocketSubscribe<{
    htmlLink: {
      teach: string;
      coach_basic: string;
      coach_intermediate: string;
      coach_advanced: string;
    };
    slide: number;
    step: number;
    currentState: StateKeys;
    studentID: number;
  }>('htmlSlidesEveToClient', htmlSlidesHandler);

  // Socket handler for tutorJoined event
  const tutorJoinedHandler = useCallback(
    ({
      slide,
      step,
    }: {
      slide: {
        coach_basic: number;
        coach_intermediate: number;
        coach_advanced: number;
        teach: number;
      };
      step: {
        coach_basic: number;
        coach_intermediate: number;
        coach_advanced: number;
        teach: number;
      };
    }) => {
      setTimeout(() => {
        sendJumpToAnimMessage(step?.[myCurrentState], slide?.[myCurrentState]);
      }, 500);
    },
    [myCurrentState, sendJumpToAnimMessage],
  );

  useSocketSubscribe<{
    slide: {
      coach_basic: number;
      coach_intermediate: number;
      coach_advanced: number;
      teach: number;
    };
    step: {
      coach_basic: number;
      coach_intermediate: number;
      coach_advanced: number;
      teach: number;
    };
  }>('tutorJoinedToClient', tutorJoinedHandler);

  // Iframe load event handler
  const handleIframeLoad = useCallback(() => {
    const timeoutId = setTimeout(() => {
      sendJumpToAnimMessage(getHtmlStep, getHtmlSlide);
      setIframeLoaded(false);
      return () => {
        clearTimeout(timeoutId);
      };
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [getHtmlSlide, getHtmlStep, sendJumpToAnimMessage]);

  const queryParams = {
    htmlLink,
    classId,
    studentId,
    currentState: myCurrentState,
  };

  // Construct iframe link
  const link = constructContentLink(queryParams);

  // Handle screenshot message from iframe
  useEffect(() => {
    const handleScreenshotMessage = async (event: MessageEvent) => {
      // Verify message origin and type
      if (event.data.type !== 'uprio_custom' || event.data.evt !== 'sending_screenShot') {
        return;
      }

      const capturedImg = event.data.data;
      if (!capturedImg) {
        datadogLog('Screenshot capture failed: No data received', {
          classId: classId ? classId.toString() : '',
          studentId: studentId ? studentId?.toString() : '',
          error: 'No screenshot data received',
        });
        return;
      }

      try {
        // Handle different types of image data
        let imageBlob: Blob | null = null;

        if (capturedImg instanceof Blob) {
          imageBlob = capturedImg;
        } else if (capturedImg instanceof HTMLCanvasElement) {
          imageBlob = await new Promise<Blob | null>((resolve) =>
            capturedImg.toBlob(resolve, 'image/png'),
          );
        } else if (typeof capturedImg === 'string' && capturedImg.startsWith('data:')) {
          const response = await fetch(capturedImg);
          imageBlob = await response.blob();
        } else {
          datadogLog('Screenshot capture failed: Unsupported format', {
            classId: classId ? classId.toString() : '',
            studentId: studentId ? studentId?.toString() : '',
            error: 'Unsupported image format',
            format: typeof capturedImg,
          });
          return;
        }

        if (!imageBlob) {
          datadogLog('Screenshot capture failed: Blob creation failed', {
            classId: classId ? classId.toString() : '',
            studentId: studentId ? studentId?.toString() : '',
            error: 'Failed to create image blob',
          });
          return;
        }

        const file = new File([imageBlob], 'slide-screenshot.png', { type: 'image/png' });

        const fileMessage: FileMessagePayload = { type: 'file', file };
        await meeting.chat.sendMessage(fileMessage, [tutorId]);

        datadogLog('Slide Screenshot downloaded successfully', {
          classId: classId ? classId.toString() : '',
          studentId: studentId ? studentId?.toString() : '',
          fileSize: file.size.toString(),
          fileType: file.type.toString(),
        });
      } catch (error) {
        datadogLog('Error processing screenshot', {
          error: error instanceof Error ? error.message : String(error),
          classId: classId ? classId.toString() : '',
          studentId: studentId ? studentId?.toString() : '',
          step: 'screenshot_processing',
        });
      }
    };

    window.addEventListener('message', handleScreenshotMessage);
    return () => window.removeEventListener('message', handleScreenshotMessage);
  }, [classId, datadogLog, meeting.chat, studentId, tutorId]);

  const handleIframeEvent = useCallback(
    (event: MessageEvent) => {
      const { data: messageData } = event;

      if (messageData?.type !== 'uprio_custom') {
        return;
      }

      if (messageData.evt === 'init') {
        setIframeLoaded(true);
      }

      // Check for jump animation complete us g function_response
      if (
        messageData.evt === 'function_response' &&
        messageData.fn === 'JumpToAnim' &&
        requestContentValidation
      ) {
        setIsJumpAnimationComplete(messageData.status === 'success');
      }

      // Handle current step and slide
      if (messageData.evt === 'current_step_slide') {
        setCurrentStepSlide({
          step: messageData.step,
          slide: messageData.slide,
        });
      }
    },
    [requestContentValidation],
  );

  useEffect(() => {
    if (requestContentValidation && isJumpAnimationComplete && iframeLoaded) {
      setTimeout(() => {
        iframeRef.current?.contentWindow?.postMessage(
          {
            type: 'uprio_custom',
            evt: 'get_current_step_slide',
          },
          '*',
        );
        setIsJumpAnimationComplete(false);
      }, 4000); //adding this in env so that they can make a change
    }
  }, [requestContentValidation, isJumpAnimationComplete, iframeLoaded]);

  // Add event listener for iframe messages
  useEffect(() => {
    window.addEventListener('message', handleIframeEvent);
    return () => window.removeEventListener('message', handleIframeEvent);
  }, [handleIframeEvent]);

  return (
    <div className='relative flex flex-col size-full'>
      <div className='notes-heading'>
        <span className='pb-1 pl-2 text-white text-md'>Notes</span>
      </div>
      <div className='h-full screenshot-content-container'>
        {htmlLink ? (
          <iframe
            src={link}
            ref={iframeRef}
            title="Tutor's slide"
            allowFullScreen={true}
            className='size-full'
            onLoad={handleIframeLoad}
            style={{
              position: 'relative',
              zIndex: 1,
            }}
          ></iframe>
        ) : (
          <div className='flex items-center justify-center text-white size-full'>
            Please inform the tutor that you are unable to see any content
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
