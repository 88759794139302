import { menuItems } from 'configs/reports';
import { IMenuIconsProps } from 'types/reports';

const MenuIcons = ({ setActiveSection, activeSection, className = '' }: IMenuIconsProps) => (
  <div className={`flex lg:flex-col gap-2 ${className}`}>
    {menuItems.map((item) => (
      <button
        key={item.id}
        onClick={() => setActiveSection(item.id)}
        className={`p-1 rounded-lg transition-all duration-200 ${
          activeSection === item.id
            ? 'bg-indigo-100 text-indigo-700'
            : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
        }`}
      >
        <div className='w-8 h-8 flex items-center justify-center font-medium font-sora'>
          {item.id}
        </div>
      </button>
    ))}
  </div>
);

export default MenuIcons;
