import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  OpenRoutes,
  ProtectedRouteWithAdminToken,
  ProtectedRouteWithLoginToken,
  ProtectedRouteWithTempToken,
} from 'layouts/RoutesConfig';

import { ADMIN_ROUTES, OPEN_ROUTES, PROTECTED_ROUTES, ROUTES } from 'configs';
import { Accounts, PageNotFound, QrCodeFlow, StudentClassroom } from 'pages';

// Define a type for the route configuration
interface RouteConfig {
  path: string;
  element: JSX.Element;
  children?: RouteConfig[];
}

// Recursive function to render nested routes
const renderNestedRoutes = (routes: RouteConfig[]) =>
  routes.map(({ path, element, children }) => (
    <Route key={path} path={path} element={element}>
      {children && renderNestedRoutes(children)}
    </Route>
  ));

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Open routes */}
        <Route element={<OpenRoutes />}>
          {OPEN_ROUTES.map(({ path, element }) => {
            return <Route key={path} path={path} element={element} />;
          })}
        </Route>

        <Route path={ROUTES.PRACTICE_CLASS_QRCODE_CAPTURE} element={<QrCodeFlow />} />
        {/* Student classroom route */}
        <Route path={ROUTES.CLASSROOM_INVITE} element={<StudentClassroom />} />
        {/* Account holder route */}
        <Route element={<ProtectedRouteWithTempToken />}>
          <Route path={ROUTES.ACCOUNTS} element={<Accounts />} />;
        </Route>

        {/* Protected routes */}
        <Route element={<ProtectedRouteWithLoginToken />}>
          {renderNestedRoutes(PROTECTED_ROUTES)}
        </Route>

        {/* Admin routes */}
        <Route element={<ProtectedRouteWithAdminToken />}>{renderNestedRoutes(ADMIN_ROUTES)}</Route>

        {/* Catch all route */}
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
