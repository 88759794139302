import MobileQuestionCategoryCard from 'components/screens/reports/MobileQuestionCategoryCard';
import QuestionCategoryCard from 'components/screens/reports/QuestionCategoryCard';
import SectionHeader from 'components/screens/reports/SectionHeader';
import { BLOCK_CATEGORY_EXPAND_FURTHUR, evaluationsData } from 'configs/reports';
import { getDotColor } from 'configs/studentReport';
import { ClassDetailToUnderstadingConnectionContext } from 'contexts/ClassDetailsToUnderstandingConnectionContext';
import { UnderstandingToCategoryConnectionContext } from 'contexts/UnderstandingToCategoryConnectionContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Connection, QuestionCategoryEntity } from 'types/reports';

const QuestionCategory = () => {
  const { chapterId, studentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth < 768);
  const [selectedCategory, setSelectedCategory] = useState<QuestionCategoryEntity | null>(null);
  const { classDetailRef, understandingLevelData, categoriesData } = useContext(
    ClassDetailToUnderstadingConnectionContext,
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const underStandingRefs = useRef<Array<HTMLDivElement | null>>([]);
  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const [connections, setConnections] = useState<Connection[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });

  const filteredCategories =
    categoriesData?.filter((category) => {
      const { categoryId } = category;
      const coveredCategories = selectedCategory?.categoriesCovered || [];
      return coveredCategories.some((coveredCategory) => coveredCategory?.id === categoryId);
    }) || [];

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef?.current || !classDetailRef?.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const classDetailRect = classDetailRef.current.getBoundingClientRect();
      const newConnections: Connection[] = [];

      underStandingRefs?.current?.forEach((underStandingRef, index) => {
        if (!underStandingRef) return;

        const categoryRect = underStandingRef.getBoundingClientRect();
        const evaluationId = evaluationsData[index].id;

        const startX = classDetailRect.right - containerRect.left;
        const startY = classDetailRect.top + 32 - containerRect.top;
        const endX = categoryRect.left - containerRect.left;
        const endY = categoryRect.top + categoryRect.height / 2 - containerRect.top;

        const curveWidth = 40;
        const curveRadius = 30;
        const yDifference = Math.abs(endY - startY);
        const isDescending = endY > startY;
        const minLineHeight = 40;

        const isNearlyHorizontal = yDifference < minLineHeight;

        let startCurve, endCurve, line;

        if (isNearlyHorizontal) {
          startCurve = ``;

          endCurve = `
        M ${endX - curveWidth} ${endY}
        H ${endX}
      `;

          line = {
            top: startY - 1,
            left: startX + curveWidth - 1,
            height: 2,
          };
        } else {
          startCurve = `
        M ${startX} ${startY}
        H ${startX + curveWidth - curveRadius}
        Q ${startX + curveWidth} ${startY},
          ${startX + curveWidth} ${startY + (isDescending ? curveRadius : -curveRadius)}
        V ${isDescending ? endY - curveRadius : endY + curveRadius}
      `;

          endCurve = `
        M ${endX - curveWidth} ${isDescending ? endY - curveRadius : startY - curveRadius}
        V ${endY - (isDescending ? curveRadius : -curveRadius)}
        Q ${endX - curveWidth} ${endY},
          ${endX - curveWidth + curveRadius} ${endY}
        H ${endX}
      `;

          line = {
            top: Math.min(
              startY + (isDescending ? curveRadius - 1 : 0),
              endY + (isDescending ? 0 : curveRadius - 1),
            ),
            left: startX + curveWidth - 1,
            height: Math.abs(endY - startY) - curveRadius * 2 + 2,
          };
        }

        newConnections.push({
          startCurve,
          endCurve,
          line,
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: endY },
          evaluationId,
        });
      });

      setConnections(newConnections);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };
    if (selectedCategory || classDetailRef) {
      calculateConnections();
      const observer = new ResizeObserver(calculateConnections);
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
      if (classDetailRef?.current) {
        observer.observe(classDetailRef.current);
      }

      window.addEventListener('resize', () => {
        setIsMobileView(window.innerWidth < 768);
        calculateConnections();
      });

      return () => {
        observer.disconnect();
        window.removeEventListener('resize', calculateConnections);
      };
    }
  }, [selectedCategory, classDetailRef]);

  const categoriesForChapter = understandingLevelData.filter((category) => {
    return category?.chapterId === chapterId;
  });

  const handleQuestionCategoryClick = (category: QuestionCategoryEntity, index: number) => {
    setSelectedCategory(category);
    setSelectedIndex(index);
    if (!BLOCK_CATEGORY_EXPAND_FURTHUR.includes(category?.text))
      navigate(
        `${prefix}/reports/${studentId}/chapter/${chapterId}/understanding/category/${index + 1}`,
      );
    else navigate(`${prefix}/reports/${studentId}/chapter/${chapterId}/understanding`);
  };

  const handleMobileClose = () => {
    setSelectedCategory(null);
    navigate(`${prefix}/reports/${studentId}/chapter/${chapterId}/understanding`);
  };

  return (
    <UnderstandingToCategoryConnectionContext.Provider
      value={{
        selectedUnderstandingRef:
          selectedIndex !== null ? { current: underStandingRefs.current[selectedIndex] } : null,
        underStandingRefs,
        categoriesData,
        selectedCategory,
      }}
    >
      <div ref={containerRef} className='relative'>
        <div className='flex gap-20'>
          <div className='flex flex-col min-w-full md:min-w-fit'>
            <SectionHeader
              text='Chapter level understanding'
              textColor='accent-purple-900'
              backgroundColor='from-[#D7BBF3] to-[#EAD7FD]'
            />
            <div className='space-y-4 md:w-[350px]'>
              {categoriesForChapter.map((category, index) =>
                selectedCategory === category && isMobileView ? (
                  <div key={category?.id}>
                    <MobileQuestionCategoryCard
                      questionCategory={category}
                      categories={filteredCategories || []}
                      onClick={handleMobileClose}
                      className='shadow-md'
                    />
                  </div>
                ) : (
                  <div
                    key={category.id}
                    ref={(el) => (underStandingRefs.current[index] = el)}
                    onClick={() => handleQuestionCategoryClick(category, index)}
                  >
                    <QuestionCategoryCard
                      questionCategory={category}
                      className='hover:shadow-lg transition-shadow'
                    />
                  </div>
                ),
              )}
            </div>
          </div>
          <div className='transition-opacity duration-300 hidden md:block'>
            <Outlet />
          </div>
        </div>

        {/* Connection Elements */}
        <div className='hidden md:block absolute inset-0 pointer-events-none'>
          <svg
            className='absolute top-0 left-0'
            width={svgDimensions.width}
            height={svgDimensions.height}
            style={{ overflow: 'visible' }}
          >
            {connections.map((conn, index) => (
              <g key={`curves-${index}`}>
                {/* Connection Curves */}
                <path
                  d={conn.startCurve}
                  fill='none'
                  stroke='#B4B6C5'
                  strokeWidth='2'
                  strokeLinecap='round'
                  className='transition-all duration-300'
                />
                <path
                  d={conn.endCurve}
                  fill='none'
                  stroke='#B4B6C5'
                  strokeWidth='2'
                  strokeLinecap='round'
                  className='transition-all duration-300'
                />
                {/* Start Half-Cycle Dot */}
                <path
                  d={`M ${conn.startDot.x},${conn.startDot.y - 5} 
                    A 5,5 0 1,1 ${conn.startDot.x},${conn.startDot.y + 5}`}
                  fill='#148D8A'
                />
                {/* End Half-Cycle Dot */}
                <path
                  d={`M ${conn.endDot.x},${conn.endDot.y - 5} 
                    A 5,5 0 1,0 ${conn.endDot.x},${conn.endDot.y + 5}`}
                  fill={getDotColor(conn.evaluationId ?? 1)}
                />
              </g>
            ))}
          </svg>
        </div>
      </div>
    </UnderstandingToCategoryConnectionContext.Provider>
  );
};

export default QuestionCategory;
