import React from 'react';
import classNames from 'classnames';
import Tag from '../Tag';
import { ChapterClassCardProps, PracticeEvaluationEntity } from 'types/reports';
import {
  AbsentIcon,
  CheckCircleIcon,
  CrossCircleIcon,
  NotebookEvaluationIcon,
  PracticeIcon,
  PresentIcon,
} from 'assets/svg';
import {
  CORRECT,
  ERROR,
  INCORRECT_ANSWER,
  PRESENT,
  SUBMITTED,
  SUBMITTED_STATUS,
  SUCCESS,
} from 'configs/reports';
import { startCase } from 'lodash';
import { Tooltip } from 'flowbite-react';

const ChapterClassCard: React.FC<ChapterClassCardProps> = ({
  chapterClass,
  className,
  practiceSheetToRender,
}) => {
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const calculateEvaluationStats = (evaluation: PracticeEvaluationEntity) => {
    const correctQuestions =
      evaluation?.questions?.filter((q) => q?.score === CORRECT)?.length || 0;
    const incorrectQuestions =
      evaluation?.questions?.filter((q) => INCORRECT_ANSWER.includes(q?.score))?.length || 0;

    return {
      correctQuestions,
      incorrectQuestions,
      completionPercentage: Math.round((evaluation?.completed / evaluation?.total) * 100),
    };
  };
  return (
    <div className={classNames('relative bg-white rounded-[12px]', className)}>
      <div className='mb-[12px]'>
        <div className='flex items-center justify-between'>
          <span className='text-xs font-medium font-poppins text-neutral-300'>
            {chapterClass?.date}
          </span>
          <Tag
            text={chapterClass?.attendance}
            iconLeft={
              chapterClass?.attendance?.toLocaleLowerCase() === 'present' ? PresentIcon : AbsentIcon
            }
            className={
              chapterClass?.attendance?.toLocaleLowerCase() === 'present' ? SUCCESS : ERROR
            }
          />
        </div>

        <div className='flex items-center justify-between mt-2'>
          <h3 className='text-sm font-semibold text-neutral-950'>
            {chapterClass?.type.split('_').join(' ').charAt(0).toUpperCase() +
              chapterClass?.type.split('_').join(' ').slice(1).toLocaleLowerCase()}
          </h3>
        </div>
      </div>

      {chapterClass?.notebookEvaluations ||
      chapterClass?.pollEvaluations ||
      practiceSheetToRender?.length > 0 ? (
        <div className='flex flex-col p-[8px] bg-neutral-50 rounded-[8px] space-y-2'>
          {!chapterClass?.notebookEvaluations ? null : (
            <div className='space-y-1'>
              <div className='flex items-center justify-start text-neutral-300'>
                <div className='w-[32px] h-[32px] p-[7px] rounded-[50%] border-[1px] border-solid border-neutral-100 mr-[8px]'>
                  <img
                    src={NotebookEvaluationIcon}
                    alt='notebook icon'
                    className='w-[18px] h-[18px]'
                  />
                </div>
                <div className='flex flex-col flex-1'>
                  <span className='text-sm font-medium font-sora text-neutral-600 mr-[4px]'>
                    Notebook Evaluations:
                    <span className='pl-2 text-sm font-semibold font-poppins text-neutral-950'>
                      {chapterClass?.notebookEvaluations}
                    </span>
                  </span>
                  {chapterClass?.attendance === PRESENT && chapterClass?.missedNotebook && (
                    <div className='text-xs font-sora text-amber-600'>
                      Student Missed Evaluation
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!chapterClass.pollEvaluations ? null : (
            <div className='space-y-1'>
              <div className='flex items-center justify-start text-neutral-300'>
                <div className='w-[32px] h-[32px] p-[7px] rounded-[50%] border-[1px] border-solid border-neutral-100 mr-[8px]'>
                  <img
                    src={NotebookEvaluationIcon}
                    alt='notebook icon'
                    className='w-[18px] h-[18px]'
                  />
                </div>
                <div className='flex flex-col flex-1'>
                  <span className='text-sm font-medium font-sora text-neutral-600 mr-[4px]'>
                    Poll Evaluations:
                    <span className='pl-2 text-sm font-semibold font-poppins text-neutral-950'>
                      {chapterClass.pollEvaluations}
                    </span>
                  </span>
                  {chapterClass?.attendance === PRESENT && chapterClass?.missedPoll && (
                    <div className='text-xs font-sora text-orange-600'>
                      Student Missed Evaluation
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {practiceSheetToRender?.map((practiceSheet) => {
            return (
              <div key={practiceSheet?.practiceSheetId} className='mb-4'>
                <div className='flex justify-between items-center mb-4'>
                  <div className='flex items-center'>
                    <div className='w-[32px] h-[32px] p-[7px] rounded-[50%] border-[1px] border-solid border-neutral-100 mr-[8px]'>
                      <img src={PracticeIcon} alt='notebook icon' className='w-[18px] h-[18px]' />
                    </div>
                    <h2 className='text-sm font-semibold font-sora text-neutral-600 '>
                      Practice Sheet
                    </h2>
                  </div>
                  <span
                    className={`px-3 py-1 text-xs font-semibold font-sora rounded-full ${
                      SUBMITTED_STATUS.includes(practiceSheet?.status)
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}
                  >
                    {SUBMITTED_STATUS.includes(practiceSheet?.status)
                      ? SUBMITTED
                      : startCase(practiceSheet?.status)}
                  </span>
                </div>

                <div className='space-y-4'>
                  {practiceSheet?.evaluations?.map((evaluation) => {
                    const stats = calculateEvaluationStats(evaluation);

                    return (
                      <div key={evaluation?.evaluationId} className='bg-gray-50 rounded-lg p-2'>
                        <div className='flex justify-between items-center mb-2'>
                          <div className='text-xs font-semibold font-sora text-gray-700 cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]'>
                            <Tooltip
                              style='light'
                              content={
                                <span className='text-sm font-semibold whitespace-pre-wrap'>
                                  {evaluation?.evaluationName}
                                </span>
                              }
                              color={'foreground'}
                              placement='top-start'
                            >
                              <p className='text-sm font-semibold truncate cursor-pointer max-w-40 whitespace-nowrap'>
                                {evaluation?.evaluationName}
                              </p>
                            </Tooltip>
                          </div>
                          <span className='text-xs font-semibold font-sora text-gray-600'>
                            {evaluation?.completed} / {evaluation?.total} Questions
                          </span>
                        </div>

                        {SUBMITTED_STATUS.includes(practiceSheet?.status) &&
                          evaluation?.completed != 0 && (
                            <div className='flex items-center space-x-4'>
                              <div className='flex items-center space-x-2'>
                                <img src={CheckCircleIcon} alt='info icon' width={18} height={18} />
                                <span className='text-xs font-semibold font-sora text-green-800'>
                                  {stats?.correctQuestions} Correct
                                </span>
                              </div>
                              <div className='flex items-center space-x-2'>
                                <img src={CrossCircleIcon} alt='info icon' width={18} height={18} />
                                <span className='text-xs font-semibold font-sora text-red-800'>
                                  {stats?.incorrectQuestions} Incorrect
                                </span>
                              </div>
                              <div className='ml-auto text-xs font-semibold font-sora text-gray-600'>
                                {stats?.completionPercentage}% Completed
                              </div>
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>

                <div className='mt-4 text-xs font-semibold font-sora text-gray-500'>
                  Assigned on:&nbsp;{' '}
                  {practiceSheet?.assignedOn?.toLocaleDateString('en-GB', options)}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='text-start px-4 text-sm font-medium font-sora text-gray-500 p-[8px] bg-neutral-50 rounded-[8px]'>
          No Evaluations Done
        </div>
      )}
    </div>
  );
};

export default ChapterClassCard;
