import { ComingSoonBadgeProps } from 'types/reports';

const ComingSoonBadge: React.FC<ComingSoonBadgeProps> = ({ className = '' }) => (
  <span
    className={`px-2 py-1 text-[10px] bg-gray-50 text-gray-400 rounded-full font-medium font-sora ${className}`}
  >
    Coming soon
  </span>
);

export default ComingSoonBadge;
