import React from 'react';
import classNames from 'classnames';
import { QuestionCategoryCardProps } from 'types/reports';
import Tag from '../Tag';
import { BulletIcon, InfoIconBlue, RedBulletIcon, RedirectIcon, StarIcon } from 'assets/svg';
import {
  BLOCK_CATEGORY_EXPAND_FURTHUR,
  CANT_EVALUATE_GAPS,
  categoryNotAssessedNote,
  PRACTICE,
  RE_EXPLAIN,
} from 'configs/reports';
import { getCountBadgeStatus, getStatusClasses } from 'configs/studentReport';
import { Tooltip } from 'flowbite-react';

const QuestionCategoryCard: React.FC<QuestionCategoryCardProps> = ({
  questionCategory,
  onClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        `relative bg-white rounded-lg px-4 py-3 cursor-pointer flex items-center before:content-[''] before:absolute before:top-4 before:bottom-4 before:left-[-2px] before:w-1 before:rounded-r`,
        getStatusClasses(questionCategory?.id),
        className,
      )}
      onClick={onClick}
    >
      <div className='flex flex-col gap-2 w-full'>
        <div className='flex justify-between items-center text-neutral-950 font-semibold text-sm font-sora'>
          <div className='flex'>
            <h2 className='max-w-[190px]'>{questionCategory?.text}</h2>
            {BLOCK_CATEGORY_EXPAND_FURTHUR.includes(questionCategory?.text) && (
              <Tooltip
                style='light'
                content={
                  <div className='w-full bg-white'>
                    <div className='space-y-1'>
                      {categoryNotAssessedNote.map((note, index) => (
                        <div key={index} className='flex items-start gap-x-2'>
                          <img
                            src={note.highlight ? RedBulletIcon : BulletIcon}
                            alt='bullet point'
                            className='w-4 h-4'
                          />
                          <span className='font-sora font-medium text-xs text-neutral-500'>
                            {note.text}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                }
              >
                <img
                  src={InfoIconBlue}
                  alt='info icon'
                  className='ml-2 cursor-pointer'
                  width={20}
                  height={20}
                />
              </Tooltip>
            )}
          </div>
          <div className='flex items-center gap-2'>
            {questionCategory?.categoriesCount && questionCategory?.totalCategoriesCount ? (
              <span
                className={classNames(
                  'px-2 py-[1px] rounded-[24px] text-xs font-normal !font-roboto',
                  getCountBadgeStatus(questionCategory?.id),
                )}
              >
                <span className='font-bold'>{questionCategory?.categoriesCount}</span>/
                {questionCategory?.totalCategoriesCount}
              </span>
            ) : null}

            {!BLOCK_CATEGORY_EXPAND_FURTHUR.includes(questionCategory?.text) && (
              <img src={RedirectIcon} alt='redirect icon' width={20} height={20} />
            )}
          </div>
        </div>

        {questionCategory?.rating && questionCategory?.rating > 0 ? (
          <div className='flex mb-2'>
            {Array.from({ length: questionCategory?.rating }, (_, i) => (
              <img src={StarIcon} width={24} height={24} alt='star' key={i} />
            ))}
          </div>
        ) : null}

        {/* {BLOCK_CATEGORY_EXPAND_FURTHUR.includes(questionCategory?.text) && (
          <>
            <div className='w-full bg-white p-2'>
              <div className='mb-1'>
                <h4 className='font-sora font-semibold text-xs text-[#2A9D8F]'>Note</h4>
              </div>
              <div className='space-y-1'>
                {categoryNotAssessedNote.map((note, index) => (
                  <div key={index} className='flex items-start gap-x-2'>
                    <img
                      src={note.highlight ? RedBulletIcon : BulletIcon}
                      alt='bullet point'
                      className='w-4 h-4 mt-0.5'
                    />
                    <span className='font-sora font-medium text-xs text-neutral-500'>
                      {note.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )} */}

        {questionCategory?.categoriesCovered && questionCategory?.categoriesCovered?.length > 0 && (
          <div className='flex flex-col p-[8px] bg-neutral-50 rounded-[8px] space-y-2'>
            <div className='flex flex-col space-y-1'>
              {questionCategory?.categoriesCovered.map((category, index) => {
                const isCantEvaluate = CANT_EVALUATE_GAPS.includes(category?.status);
                return isCantEvaluate ? (
                  <div
                    key={index}
                    className='flex items-start gap-x-2 font-sora font-medium text-sm'
                  >
                    <div className='flex items-start gap-x-2'>
                      {category?.status === 'absent' ? (
                        <>
                          <img src={RedBulletIcon} alt='bullet point' width={16} height={16} />
                          <span className='text-neutral-500'>{category?.name}</span>
                        </>
                      ) : (
                        <>
                          <img src={BulletIcon} alt='bullet point' width={16} height={16} />
                          <span className='text-neutral-500'>{category?.name}</span>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className='flex items-start gap-x-2 font-sora font-medium text-sm'
                  >
                    <img src={BulletIcon} alt='bullet point' width={16} height={16} />
                    <span className='text-neutral-500'>{category?.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {questionCategory?.interventionTypes && questionCategory?.interventionTypes?.length > 0 ? (
          <div className='flex items-center text-neutral-500 text-sm font-semibold font-sora gap-1'>
            <span>Intervention:</span>
            {questionCategory?.interventionTypes.map((interventionType, index) => (
              <Tag
                text={interventionType}
                key={index}
                className={`bordered ${
                  interventionType?.toLocaleLowerCase() === PRACTICE
                    ? 'secondary'
                    : interventionType?.toLocaleLowerCase() === RE_EXPLAIN
                      ? 'primary'
                      : ''
                }`}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionCategoryCard;
