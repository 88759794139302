import { createContext, RefObject } from 'react';
import { CategoryEntity, QuestionCategoryEntity } from 'types/reports';

interface ClassDetailToUnderstadingConnectionContextType {
  classDetailRef: RefObject<HTMLDivElement> | null;
  understandingLevelData: QuestionCategoryEntity[];
  evaluationData: QuestionCategoryEntity[];
  categoriesData: CategoryEntity[];
}

export const ClassDetailToUnderstadingConnectionContext =
  createContext<ClassDetailToUnderstadingConnectionContextType>({
    classDetailRef: null,
    understandingLevelData: [],
    evaluationData: [],
    categoriesData: [],
  });
