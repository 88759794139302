import { BouncingDotsLoader } from 'components/common';
import { USER_TYPE } from 'configs';
import { SANITY_EPIC_SEARCH_NAMES } from 'configs/common';
import { useSanityOptions } from 'hooks/useSanityOptions';
import { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetSiblingParentListDetailsQuery } from 'store/apiSlices/parentDashboard.apiSlice';
import DashboardLayout from './DashboardLayout';
import {
  useGetCategoryBasedReportsQuery,
  useGetChapterStepLevelAnswersDataMutation,
  useGetPracticeSheetEvaluationDetailsQuery,
} from 'store/apiSlices/reports.apiSlice';
import {
  CategoryEntity,
  ChapterClassEntity,
  ChapterEntity,
  Connection,
  PracticeSheetAssignPayload,
  PracticeSheetEntity,
  QuestionCategoryEntity,
  QuestionCategorySummary,
  QuestionEntity,
} from 'types/reports';
import SectionHeader from 'components/screens/reports/SectionHeader';
import ChapterCard from 'components/screens/reports/ChapterCard';
import {
  getCategoryEntity,
  getChapterClassEntity,
  getChaptersEntity,
  getPracticeSheetEntity,
  getQuestionCategoriesEntity,
  getQuestionsEntity,
} from 'configs/studentReport';
import ClassDetailCard from 'components/screens/reports/ClassDetailCard';
import { NO_CHAPTERS_FOUND, NO_CHAPTERS_FOUND_EXPLAINATION } from 'configs/reports';
import { ClassDetailToUnderstadingConnectionContext } from 'contexts/ClassDetailsToUnderstandingConnectionContext';
import { BackIcon } from 'assets/svg';
import { setChapterWiseData } from 'store/slice/reports.slice';
import { useAppDispatch } from 'hooks/store';
import BottomSheet from 'components/screens/reports/BottomSheet';
import CategoryDetailCard from 'components/screens/reports/CategoryDetailCard';
import { useWindowDimensions } from 'hooks';
import Cookie from 'js-cookie';

const Reports = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isMobileScreen } = useWindowDimensions();

  const [selectedSegment, setSelectedSegment] = useState<string>('');
  const [chapters, setChapters] = useState<ChapterEntity[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<ChapterEntity | null>(null);
  const [assignedPracticeSheet, setAssignedPracticeSheet] = useState<PracticeSheetAssignPayload[]>(
    [],
  );
  const [chapterClasses, setChapterClasses] = useState<ChapterClassEntity[]>([]);
  const [filteredClasses, setFilteredClasses] = useState<ChapterClassEntity[]>([]);
  const [evaluationData, setEvaluationData] = useState<QuestionCategoryEntity[]>([]);
  const [categoriesEvaluated, setCategoriesEvaluated] = useState<string[]>([]);
  const [categoriesData, setCategoriesData] = useState<CategoryEntity[]>([]);
  const [questions, setQuestions] = useState<QuestionEntity[]>([]);
  const [understandingLevelData, setUnderStandingLevelData] = useState<QuestionCategoryEntity[]>(
    [],
  );
  const [practiceSheets, setPracticeSheets] = useState<PracticeSheetEntity[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(isMobileScreen);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const [isBottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // Check if we're on the understanding route
  const isUnderstandingRoute = location.pathname.includes('/understanding');
  const isCategoryRoute = location.pathname.includes('/category');

  const { studentId, chapterId, categoryId, understandingLevel } = useParams();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedChapterRef = useRef<HTMLDivElement | null>(null);
  const classDetailRef = useRef<HTMLDivElement | null>(null);

  const isAdminRoute = location.pathname.startsWith('/a');
  const prefix = isAdminRoute ? '/a' : '/p';

  const studentDetails = Cookie.get(`studentDetails`) || '';
  const { data: parentSiblingsData, isLoading: isParentSibilingIsloading } =
    useGetSiblingParentListDetailsQuery(undefined, { skip: isAdminRoute });

  let student;
  if (isAdminRoute) {
    student = { members: JSON.parse(studentDetails) };
  } else {
    student = parentSiblingsData?.data?.find(
      (item) =>
        item.members.id === Number(studentId) && item.members.userType === USER_TYPE.STUDENT,
    );
  }

  const { data: subjectsList, isLoading: loadingSubject } = useSanityOptions(
    SANITY_EPIC_SEARCH_NAMES.SUBJECTS,
  );

  const [triggerForStepLevelData, { data: stepLevelAnswers }] =
    useGetChapterStepLevelAnswersDataMutation();

  const { data: reportData, isLoading: reportDataLoading } = useGetCategoryBasedReportsQuery(
    {
      studentId: studentId && !isNaN(Number(studentId)) ? Number(studentId) : null,
      subjectId: selectedSegment,
    },
    { skip: !studentId || isNaN(Number(studentId)) || !selectedSegment },
  );

  const queryParams = useMemo(() => {
    if (assignedPracticeSheet?.length > 0) {
      const practiceSheetMap: Record<string, string[]> = {};
      assignedPracticeSheet?.forEach(({ practiceSheetId, evaluationId }) => {
        if (!practiceSheetId) return;

        if (!practiceSheetMap[practiceSheetId]) {
          practiceSheetMap[practiceSheetId] = [];
        }
        practiceSheetMap[practiceSheetId].push(evaluationId);
      });

      // Convert the grouped data into query parameter structure
      return Object.entries(practiceSheetMap).map(([practiceSheetId, evaluationIds]) => ({
        practiceSheetId,
        evaluationIds,
      }));
    }
    return [];
  }, [assignedPracticeSheet]);

  const { data: practiceEvaluationData, isLoading: practiceEvalDetailsLoading } =
    useGetPracticeSheetEvaluationDetailsQuery(
      {
        studentId: studentId ? Number(studentId) : null,
        practiceSheets: queryParams,
      },
      { skip: !queryParams.length || !studentId },
    );

  // Scroll to the right if the route includes /category
  useEffect(() => {
    if (isCategoryRoute && containerRef.current) {
      containerRef.current.scrollTo({
        left: containerRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
  }, [isCategoryRoute]);

  // Adjust scroll dynamically when category details appear
  useEffect(() => {
    setTimeout(() => {
      if (categoryId && containerRef.current) {
        const container = containerRef.current;
        const extraScrollSpace = 300; // Extra space for the category detail card
        container.scrollTo({
          left: container.scrollWidth - container.clientWidth + extraScrollSpace,
          behavior: 'smooth',
        });
      }
    }, 300);
    if (categoryId && containerRef.current) {
      const container = containerRef.current;
      const extraScrollSpace = 300; // Extra space for the category detail card
      container.scrollTo({
        left: container.scrollWidth - container.clientWidth + extraScrollSpace,
        behavior: 'smooth',
      });
    }

    if (categoryId && chapterId && questions?.length == 0) {
      navigate(`${prefix}/reports/${studentId}/chapter/${chapterId}/understanding`);
    }
  }, [categoryId, chapterId, isAdminRoute, navigate, prefix, questions?.length, studentId]);

  // Add useEffect to control popup timing
  useEffect(() => {
    if (categoryId) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setShowPopup(false);
    }
  }, [categoryId]);

  // Show the BottomSheet if a category is selected
  useEffect(() => {
    if (isMobile) {
      setBottomSheetVisible(!!categoryId);
    }
  }, [categoryId, isMobile]);

  useEffect(() => {
    setIsMobile(isMobileScreen);
  }, [isMobileScreen]);

  useEffect(() => {
    if (subjectsList?.docs) {
      const segments = subjectsList?.docs?.map((doc) => ({ name: doc?.name || '', _id: doc?._id }));
      // default segment as math for now
      setSelectedSegment(segments?.[0]?._id);
    }
  }, [subjectsList]);

  useEffect(() => {
    if (stepLevelAnswers && selectedChapter?.chapterId) {
      const qcSummaryOfThisChapter = reportData?.data?.find(
        (data) => data.chapterId === selectedChapter?.chapterId,
      )?.questionCategorySummary;
      const processedCategories = getCategoryEntity(
        qcSummaryOfThisChapter,
        stepLevelAnswers?.data,
        selectedChapter?.chapterId,
      );

      const processQuestions = getQuestionsEntity(
        qcSummaryOfThisChapter,
        stepLevelAnswers?.data,
        selectedChapter?.chapterId,
      );

      setQuestions(processQuestions);
      setCategoriesData(processedCategories);
    }
  }, [reportData?.data, selectedChapter?.chapterId, stepLevelAnswers]);

  useEffect(() => {
    const fetchChapterStepLevelAnswersData = async (questionCategoryIds: string[]) => {
      try {
        await triggerForStepLevelData({
          studentId: studentId ? Number(studentId) : null,
          subjectId: selectedSegment ? selectedSegment : null,
          chapterId: chapterId ? chapterId : null,
          questionCategoryIds,
          reportType: 'category',
        }).unwrap();
      } catch (error) {
        toast.error('Failed to fetch step level answers details.Please try again later.');
        console.error(error);
      }
    };
    if (categoriesEvaluated?.length > 0 && chapterId) {
      fetchChapterStepLevelAnswersData(categoriesEvaluated);
    }
  }, [categoriesEvaluated, chapterId, selectedSegment, studentId, triggerForStepLevelData]);

  useEffect(() => {
    if (reportData) {
      let defaultFilteredClass: ChapterClassEntity[] = [];
      let defaultChapter: ChapterEntity | null = null;
      let qcEvaluatedInChapter: QuestionCategorySummary[] = [];
      dispatch(setChapterWiseData(reportData?.data));
      const processedChapters = getChaptersEntity(reportData?.data);
      const processedChaptersClass = getChapterClassEntity(reportData?.data);
      const { questionCategoryArray, connectionData } = getQuestionCategoriesEntity(
        reportData?.data,
      );

      if (chapterId) {
        defaultChapter = processedChapters?.find((ch) => ch.chapterId === chapterId) || null;
        defaultFilteredClass = processedChaptersClass?.filter((cls) => cls.chapterId === chapterId);
        qcEvaluatedInChapter =
          reportData?.data?.find((chapters) => chapters?.chapterId === chapterId)
            ?.questionCategorySummary || [];
      } else {
        defaultFilteredClass = processedChaptersClass?.filter(
          (cls) => cls?.chapterId === processedChapters?.[0]?.chapterId,
        );
        qcEvaluatedInChapter =
          reportData?.data?.find(
            (chapters) => chapters?.chapterId === processedChapters?.[0]?.chapterId,
          )?.questionCategorySummary || [];
      }

      const questionCategoryIds = qcEvaluatedInChapter?.map((qcs) => qcs?.questionCategoryId) || [];

      setChapters(processedChapters);
      setUnderStandingLevelData(questionCategoryArray);
      setEvaluationData(connectionData);
      setChapterClasses(processedChaptersClass);
      chapterId && setSelectedChapter(defaultChapter);
      chapterId && setFilteredClasses(defaultFilteredClass);
      chapterId && setAssignedPracticeSheet(defaultChapter?.assignedPracticeSheet || []);
      setCategoriesEvaluated(questionCategoryIds);
    }
  }, [chapterId, dispatch, reportData, selectedSegment, studentId, triggerForStepLevelData]);

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const newConnections: Connection[] = [];

      if (selectedChapter && selectedChapterRef.current && classDetailRef.current) {
        const chapterRect = selectedChapterRef.current.getBoundingClientRect();
        const classRect = classDetailRef.current.getBoundingClientRect();

        // Calculate the midpoints
        const startX = chapterRect.right - containerRect.left;
        const startY = chapterRect.top + 32 - containerRect.top; // Middle of chapter card
        const endX = classRect.left - containerRect.left;
        const endY = classRect.top + 32 - containerRect.top;

        const curveWidth = 40; // Horizontal spacing for the curve
        const curveRadius = 30; // Radius for the curve
        const yDifference = Math.abs(endY - startY);
        const isDescending = endY > startY;
        const minLineHeight = 20; // Ensure a minimum height for the line

        const isNearlyHorizontal = yDifference < minLineHeight;

        let startCurve, endCurve, line;

        if (isNearlyHorizontal) {
          // For nearly horizontal connections, use a simple straight line with small curves
          startCurve = `
      M ${startX} ${startY}
      H ${startX + curveWidth - curveRadius}
      Q ${startX + curveWidth} ${startY},
        ${startX + curveWidth} ${startY}
    `;

          endCurve = `
      M ${endX - curveWidth} ${endY}
      H ${endX}
    `;

          line = {
            top: startY - 1,
            left: startX + curveWidth - 1,
            height: 2, // Minimal height for visual consistency
          };
        } else {
          // Modified curved connection logic
          const verticalDistance = Math.abs(endY - startY);
          const effectiveHeight = Math.max(verticalDistance - curveRadius * 2, 0);

          startCurve = `
          M ${startX} ${startY}
          H ${startX + curveWidth - curveRadius}
          Q ${startX + curveWidth} ${startY},
            ${startX + curveWidth} ${startY + (isDescending ? curveRadius : -curveRadius)}
        `;

          endCurve = `
          M ${endX - curveWidth} ${endY + (isDescending ? -curveRadius : curveRadius)}
          Q ${endX - curveWidth} ${endY},
            ${endX - curveWidth + curveRadius} ${endY}
          H ${endX}
        `;

          // Key change: Adjusted line height calculation
          line = {
            top: isDescending ? startY + curveRadius : endY + curveRadius,
            left: startX + curveWidth - 1,
            height: effectiveHeight,
          };
        }

        // Push the connection with calculated dots
        newConnections.push({
          startCurve,
          endCurve,
          line,
          startDot: { x: startX, y: startY }, // Position for start dot
          endDot: { x: endX, y: endY }, // Position for end dot
        });
      }

      setConnections(newConnections);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    if (selectedChapter) {
      calculateConnections();
      const observer = new ResizeObserver(calculateConnections);
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
      window.addEventListener('scroll', calculateConnections);
      window.addEventListener('resize', calculateConnections);

      return () => {
        observer.disconnect();
        window.removeEventListener('scroll', calculateConnections);
        window.removeEventListener('resize', calculateConnections);
      };
    }
  }, [selectedChapter]);

  useEffect(() => {
    if (practiceEvaluationData?.data && practiceEvaluationData?.data?.length > 0) {
      const processedPracticeSheet = getPracticeSheetEntity(
        practiceEvaluationData?.data,
        assignedPracticeSheet,
      );

      setPracticeSheets(processedPracticeSheet);
    }
  }, [assignedPracticeSheet, practiceEvaluationData]);

  const handleChapterSelect = (chapter: ChapterEntity) => {
    setSelectedChapter(chapter);
    setAssignedPracticeSheet(chapter?.assignedPracticeSheet);
    const relevantClasses =
      chapterClasses?.filter((cls) => cls.chapterId === chapter.chapterId) || [];
    setFilteredClasses([...relevantClasses]);

    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (selectedChapterRef.current) {
      window.scrollTo({
        top: 20,
        behavior: 'smooth',
      });
    }

    // Check screen size and navigate accordingly
    const isSmallScreen = window.innerWidth < 768;
    if (isSmallScreen) {
      // Redirect to the understanding route for smaller screens
      navigate(`${prefix}/reports/${studentId}/chapter/${chapter.chapterId}/understanding`);
    } else {
      // Navigate to base route when selecting a new chapter for larger screens
      if (isUnderstandingRoute) {
        navigate(`${prefix}/reports/${studentId}/chapter`);
      }
    }
  };

  const handleClassCardClick = () => {
    if (selectedChapter) {
      navigate(
        `${prefix}/reports/${studentId}/chapter/${selectedChapter?.chapterId}/understanding`,
      );
    }
  };

  // Filter questions for the selected category
  const filteredQuestions =
    chapterId && categoryId
      ? questions?.filter((q) => q?.chapterId === chapterId && q?.categoryId === categoryId)
      : [];

  const closeCategoryDetails = () => {
    setBottomSheetVisible(false);
    navigate(
      `${prefix}/reports/${studentId}/chapter/${chapterId}/understanding/category/${understandingLevel}`,
    );
  };

  const isLoading = reportDataLoading || loadingSubject || isParentSibilingIsloading;
  return (
    <DashboardLayout studentId={studentId} parentSiblingsData={parentSiblingsData}>
      {isLoading ? (
        <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
          <BouncingDotsLoader />
        </div>
      ) : (
        <ClassDetailToUnderstadingConnectionContext.Provider
          value={{
            classDetailRef,
            understandingLevelData,
            evaluationData,
            categoriesData: categoriesData,
          }}
        >
          <div className='pt-20 lg:pt-0 lg:pl-20'>
            {!chapters.length ? (
              <div className='flex justify-center items-center h-fit mt-6'>
                <div className='bg-white shadow-lg rounded-lg p-8 w-full max-w-md'>
                  <h2 className='text-2xl font-bold text-red-800 mb-4 font-sora'>
                    {NO_CHAPTERS_FOUND}
                  </h2>
                  <p className='text-gray-600 font-poppins'>{NO_CHAPTERS_FOUND_EXPLAINATION}</p>
                </div>
              </div>
            ) : (
              <div
                ref={containerRef}
                className='relative overflow-x-auto lg:p-0'
                style={{
                  overflow: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                }}
              >
                <div
                  className='flex lg:gap-20 relative z-0 m-2 lg:ms-4'
                  style={isMobile ? { flexDirection: 'column', gap: '24px' } : {}}
                >
                  <div className='flex flex-col min-w-full md:min-w-fit'>
                    {(!isUnderstandingRoute || !isMobile) && (
                      <SectionHeader
                        text={`What we have taught to ${student?.members?.firstName} so far`}
                        textColor='primary-950'
                        backgroundColor='from-[#8ECFF8] to-[#CBE9FC]'
                      />
                    )}
                    <div className='flex flex-col md:flex-row gap-3 lg:gap-20 relative w-full z-0'>
                      {(!isUnderstandingRoute || !isMobile) && (
                        <div className='space-y-4 w-full md:w-[330px] mb-2'>
                          {chapters.map((chapter) => (
                            <div
                              key={chapter.chapterId}
                              ref={
                                selectedChapter?.chapterId === chapter.chapterId
                                  ? selectedChapterRef
                                  : null
                              }
                            >
                              <ChapterCard
                                chapter={chapter}
                                isSelected={selectedChapter?.chapterId === chapter.chapterId}
                                className='hover:border-teal-500 transition-colors cursor-pointer'
                                onClick={() => handleChapterSelect(chapter)}
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Class Details Column */}
                      {isUnderstandingRoute && isMobile && selectedChapter && (
                        <BackToChapterHeader chapterName={selectedChapter.chapterName} />
                      )}

                      {/* Class Details Column */}
                      {(isUnderstandingRoute || !isMobile) &&
                        selectedChapter &&
                        !practiceEvalDetailsLoading &&
                        (chapterId ? selectedChapter?.chapterId === chapterId : true) && (
                          <div
                            className={`w-full md:w-[400px] transition-opacity duration-300 ${
                              selectedChapter ? 'opacity-100' : 'opacity-0'
                            }`}
                            onClick={handleClassCardClick}
                          >
                            {selectedChapter && (
                              <ClassDetailCard
                                ref={classDetailRef}
                                chapterClasses={filteredClasses}
                                practiceSheets={practiceSheets}
                                className='bg-white rounded-lg cursor-pointer'
                              />
                            )}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className='transition-opacity duration-300'>
                    <Outlet />
                  </div>
                </div>

                {/* Category Details - Sticking to the Right */}
                {isMobile ? (
                  <BottomSheet isVisible={isBottomSheetVisible} onClose={closeCategoryDetails}>
                    {categoryId && (
                      <CategoryDetailCard
                        idx={categoryId}
                        questions={filteredQuestions}
                        understandingLevelData={understandingLevelData}
                        selected={true}
                        onClick={closeCategoryDetails}
                      />
                    )}
                  </BottomSheet>
                ) : (
                  <div
                    className={`fixed top-5  right-8 bg-white shadow-lg rounded-lg w-[30vw] z-50 overflow-y-auto
                    transition-all duration-300 transform
                    ${showPopup ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}
                    style={{
                      marginTop: '64px',
                      height: '80vh',
                    }}
                  >
                    {categoryId && (
                      <CategoryDetailCard
                        idx={categoryId}
                        questions={filteredQuestions}
                        understandingLevelData={understandingLevelData}
                        selected={true}
                        onClick={closeCategoryDetails}
                      />
                    )}
                  </div>
                )}

                {/* Connection Elements */}
                <div className='hidden md:block absolute inset-0 pointer-events-none'>
                  <svg
                    className='absolute top-0 left-0'
                    width={svgDimensions.width}
                    height={svgDimensions.height}
                    style={{ overflow: 'visible' }}
                  >
                    {connections.map((conn, index) => (
                      <g key={`curves-${index}`}>
                        {conn.startCurve && (
                          <path
                            d={conn.startCurve}
                            fill='none'
                            stroke='#B4B6C5'
                            strokeWidth='2'
                            strokeLinecap='round'
                            className='transition-all duration-300'
                          />
                        )}
                        {conn.endCurve && (
                          <path
                            d={conn.endCurve}
                            fill='none'
                            stroke='#B4B6C5'
                            strokeWidth='2'
                            strokeLinecap='round'
                            className='transition-all duration-300'
                          />
                        )}
                        {conn.startDot && (
                          <path
                            d={`M ${conn.startDot.x},${conn.startDot.y - 5} 
                            A 5,5 0 1,1 ${conn.startDot.x},${conn.startDot.y + 5}`}
                            fill='#086ac5'
                          />
                        )}
                        {conn.endDot && (
                          <path
                            d={`M ${conn.endDot.x},${conn.endDot.y - 5} 
                            A 5,5 0 1,0 ${conn.endDot.x},${conn.endDot.y + 5}`}
                            fill='#148D8A'
                          />
                        )}
                      </g>
                    ))}
                  </svg>
                  {connections.map(
                    (conn, index) =>
                      conn.line && (
                        <div
                          key={`line-${index}`}
                          className='absolute bg-neutral-300 w-0.5 transition-all duration-300'
                          style={{
                            top: `${conn.line.top}px`,
                            left: `${conn.line.left}px`,
                            height: `${conn.line.height}px`,
                          }}
                        />
                      ),
                  )}
                </div>
              </div>
            )}
          </div>
        </ClassDetailToUnderstadingConnectionContext.Provider>
      )}
    </DashboardLayout>
  );
};

export default Reports;

const BackToChapterHeader = ({ chapterName }: { chapterName: string | undefined }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/a');
  const { studentId } = useParams();
  const prefix = isAdminRoute ? '/a' : '/p';

  const handleNavigate = () => {
    navigate(`${prefix}/reports/${studentId}/chapter`);
  };

  return (
    <div className='flex items-center gap-2 rounded-md'>
      <button
        className='flex items-center text-primary-600 hover:text-primary-700'
        onClick={() => handleNavigate()}
      >
        <img src={BackIcon} alt='Back' className='w-5 h-5' />
      </button>
      <span className='font-semibold text-center text-neutral-950'>{chapterName}</span>
    </div>
  );
};
