import {
  CategoryBasedReport,
  CategoryBasedReportRequest,
  ChapterWiseStepLevelData,
  ChapterWiseStepLevelDataRequest,
  PracticeEvaluationRequest,
  practiceSheetResponse,
} from 'types/reports';
import apiSlice from '.';
import QueryString from 'qs';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryBasedReports: builder.query<CategoryBasedReport, CategoryBasedReportRequest>({
      query: ({ studentId, subjectId }) => ({
        url: `v2/reports/qc-gap/${studentId}?` + QueryString.stringify({ subjectId }),
        method: 'GET',
      }),
    }),

    getChapterStepLevelAnswersData: builder.mutation<
      ChapterWiseStepLevelData,
      ChapterWiseStepLevelDataRequest
    >({
      query: (body) => ({
        url: 'v2/reports/qc-gap/chapter-wise-step-level-answers',
        method: 'POST',
        body,
      }),
    }),

    getPracticeSheetEvaluationDetails: builder.query<
      practiceSheetResponse,
      PracticeEvaluationRequest
    >({
      query: ({ studentId, practiceSheets }) => {
        const queryString = QueryString.stringify({ practiceSheets });
        return {
          url: `v2/reports/qc-gap/practice-sheet/${studentId}?${queryString}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetCategoryBasedReportsQuery,
  useGetChapterStepLevelAnswersDataMutation,
  useGetPracticeSheetEvaluationDetailsQuery,
} = extendedApiSlice;
