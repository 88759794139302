import {
  CORRECT,
  CORRECT_STARTCASE,
  DRAFT,
  EVALUATION,
  GAP_HI,
  GAP_LI,
  GAP_NI,
  GAP_SND,
  INCORRECT_STARTCASE,
  CANT_EVALUATE_GAPS,
  REGULAR_CLASS,
  REGULAR_EVAL_CLASS,
  statusToIdMap,
  TEACH,
  TEACH_EVALUATION,
  NOT_ASSESSED,
  NOT_EVALUATED,
  PRESENT,
  QUESTION_CATEGORY_ID,
  STUDENT_COULD_NOT_SOLVE,
  NOT_ATTEMPTED_STARTCASE,
} from 'configs/reports';
import { groupBy, orderBy, startCase, uniqBy } from 'lodash';
import {
  CategoryEntity,
  ChapterClassEntity,
  ChapterEntity,
  ChapterWiseData,
  PracticeSheetAssignPayload,
  PracticeSheetEntity,
  practiceSheetResponseDoc,
  QuestionCategoryEntity,
  QuestionCategorySummary,
  QuestionEntity,
  StatusKey,
  StatusKeyIdenf,
  StepLevelAnswers,
} from 'types/reports';

export const getChaptersEntity = (reportData: ChapterWiseData[] | undefined) => {
  if (!reportData?.length) {
    return [];
  }

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const reportDataByChapter = reportData?.reduce(
    (acc: ChapterEntity[], chapter: ChapterWiseData) => {
      const { chapterId, chapterName, subjectId, classes = [], practiceSheets } = chapter;

      if (chapterId && !classes?.length) {
        return acc;
      }

      const sortedClasses = [...classes].sort(
        (a, b) => new Date(a?.classDate || 0).getTime() - new Date(b?.classDate || 0).getTime(),
      );

      if (!sortedClasses?.length) return acc;

      const firstClassDate = new Date(sortedClasses?.[0]?.classDate);
      const lastClassDate = new Date(sortedClasses?.[sortedClasses.length - 1]?.classDate);

      const dateRange = `${formatDate(firstClassDate)} - ${formatDate(lastClassDate)}`;

      const totalNotebooks = classes.reduce((sum, cls) => sum + cls?.questions?.notebooks, 0);
      const totalPollEvaluations = classes.reduce((sum, cls) => sum + cls?.questions?.poll, 0);

      const totalPracticeSheetAssigned = Array.from(
        new Set(practiceSheets?.map((practice) => practice?.practiceSheetId) || []),
      )?.length;

      acc.push({
        chapterId,
        chapterName,
        subjectId,
        dateRange,
        notebookEvaluations: totalNotebooks,
        pollEvaluation: totalPollEvaluations,
        practiceSheets: totalPracticeSheetAssigned || 0,
        lastClassDate,
        assignedPracticeSheet: practiceSheets || [],
        reportStatus: chapter?.reportStatus,
      });

      return acc;
    },
    [] as ChapterEntity[],
  );

  const filteredChapters = reportDataByChapter.filter(
    (chapter) => chapter.notebookEvaluations > 0 || chapter.pollEvaluation > 0,
  );

  return filteredChapters.sort(
    (a, b) => new Date(b.lastClassDate).getTime() - new Date(a.lastClassDate).getTime(),
  );
};

export const getChapterClassEntity = (reportData: ChapterWiseData[] | undefined) => {
  if (!reportData) return [];

  const chapterClassArray: ChapterClassEntity[] = [];

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  reportData.forEach((chapter) => {
    const { chapterId, subjectId, studentId, classes = [] } = chapter;

    if (!classes?.length) return;

    const sortedClasses = [...classes].sort(
      (a, b) => new Date(a?.classDate || 0).getTime() - new Date(b?.classDate || 0).getTime(),
    );

    if (!sortedClasses?.length) return;

    sortedClasses?.forEach((classItem, index) => {
      const { classId, classDate, classType, questions, attendance } = classItem;

      if (!classId || !classDate || !classType) return;

      let type = '';
      if (classType?.includes(REGULAR_CLASS) && classType?.includes(REGULAR_EVAL_CLASS)) {
        type = TEACH_EVALUATION;
      } else if (classType?.includes(REGULAR_CLASS)) {
        type = TEACH;
      } else if (classType?.includes(REGULAR_EVAL_CLASS)) {
        type = EVALUATION;
      }

      const chapterClassEntity: ChapterClassEntity = {
        id: `${index}-${classId}`,
        chapterClassId: `${chapterId}-${classId}`,
        chapterId,
        subjectId,
        studentId: studentId?.toString(),
        date: formatDate(new Date(classDate)),
        type,
        attendance: attendance ? startCase(attendance) : PRESENT,
        notebookEvaluations: questions?.notebooks || 0,
        missedNotebook: questions?.missedNotebook || false,
        missedPoll: questions?.missedPoll || false,
        pollEvaluations: questions?.poll || 0,
      };

      chapterClassArray.push(chapterClassEntity);
    });
  });

  return chapterClassArray;
};

export const getStatusClasses = (id: number | null | undefined) => {
  switch (id) {
    case 1:
      return 'before:bg-semantic-green-500';
    case 2:
      return 'before:bg-semantic-orange-300';
    case 3:
      return 'before:bg-semantic-red-500';
    case 4:
      return 'before:bg-neutral-400';
    default:
      return 'before:bg-semantic-green-500';
  }
};
export const getCountBadgeStatus = (id: number | null | undefined) => {
  switch (id) {
    case 1:
      return 'bg-semantic-green-100 text-semantic-green-800';
    case 2:
      return 'bg-semantic-orange-100 text-semantic-orange-800';
    case 3:
      return 'bg-semantic-red-100 text-semantic-red-800';
    case 4:
      return 'bg-neutral-100 text-neutral-800';
    default:
      return 'bg-semantic-green-100 text-semantic-green-800';
  }
};

export const getPracticeSheetEntity = (
  practiceEvalData: practiceSheetResponseDoc[],
  assignedPracticeSheet: PracticeSheetAssignPayload[],
): PracticeSheetEntity[] => {
  if (!practiceEvalData?.length || !assignedPracticeSheet?.length) {
    return [];
  }

  const practiceSheets: PracticeSheetEntity[] = assignedPracticeSheet?.map((practice) => {
    const { studentSubmissionStatus, practiceSheetId, assignedOn } = practice || {};

    const practiceSheetEntity: PracticeSheetEntity = {
      practiceSheetId,
      status: studentSubmissionStatus,
      assignedOn: new Date(assignedOn),
      evaluations: [],
    };

    // Find all evaluation data related to the current practiceSheetId
    const evalData = practiceEvalData?.filter((e) => e?.practiceSheetId === practiceSheetId);

    if (evalData?.length > 0) {
      practiceSheetEntity.evaluations = evalData?.map((evl: practiceSheetResponseDoc) => {
        const evaluationName =
          assignedPracticeSheet.find(
            (e) => e?.practiceSheetId === practiceSheetId && evl?.evaluationId === e?.evaluationId,
          )?.evaluationName || '';

        return {
          evaluationId: evl?.evaluationId,
          evaluationName,
          total: evl?.totalQuestions || 0,
          completed: evl?.attemptedQuestion || 0,
          questions:
            evl?.questions?.map((question) => ({
              questionId: question?.questionId,
              questionStatus: question?.questionStatus,
              score: question?.score,
            })) || [],
        };
      });
    }

    return practiceSheetEntity;
  });

  return practiceSheets;
};

const getUnderstandingStatus = (str: string) => {
  const statusMap = {
    GAP_NI: {
      text: 'Well understood',
      badgeColor: 'success',
      stars: 5,
      interventions: ['N/A'],
    },
    GAP_LI: {
      text: 'Understood but student makes silly mistakes',
      badgeColor: 'warning',
      stars: 4,
      interventions: ['Practice'],
    },
    GAP_HI_SND: {
      text: 'Requires further reinforcement',
      badgeColor: 'danger',
      stars: 2,
      interventions: ['Re-explain', 'Practice'],
    },
    NOT_ASSESSED: {
      text: 'Categories not assessed in class',
      badgeColor: 'secondary',
      stars: 0,
      interventions: ['N/A'],
    },
  };

  return (
    statusMap[str as StatusKey] || {
      text: str,
      badgeColor: 'secondary',
      stars: 0,
      interventions: [],
    }
  );
};

export const getQuestionCategoriesEntity = (reportData: ChapterWiseData[] | undefined) => {
  if (!reportData) return { questionCategoryArray: [], connectionData: [] };

  const questionCategoryArray: QuestionCategoryEntity[] = [];
  const connectionData: QuestionCategoryEntity[] = [];

  reportData?.forEach((chapter) => {
    const { chapterId, questionCategorySummary } = chapter;

    if (!questionCategorySummary?.length) return;

    const statusGroups: {
      GAP_NI: QuestionCategorySummary[];
      GAP_LI: QuestionCategorySummary[];
      GAP_HI_SND: QuestionCategorySummary[];
      NOT_ASSESSED: QuestionCategorySummary[];
    } = {
      GAP_NI: [],
      GAP_LI: [],
      GAP_HI_SND: [],
      NOT_ASSESSED: [],
    };

    // Group question categories by their `qcGapStatus`
    questionCategorySummary?.forEach((qc: QuestionCategorySummary) => {
      if (qc?.qcGapStatus === GAP_NI) statusGroups.GAP_NI.push(qc);
      else if (qc?.qcGapStatus === GAP_LI) statusGroups.GAP_LI.push(qc);
      else if (qc?.qcGapStatus === GAP_HI || qc?.qcGapStatus === GAP_SND)
        statusGroups.GAP_HI_SND.push(qc);
      else if (CANT_EVALUATE_GAPS.includes(qc?.qcGapStatus)) statusGroups.NOT_ASSESSED.push(qc);
    });

    Object.entries(statusGroups).forEach(([statusKey, group]) => {
      let isNotAssessed = false;
      let finalGroup;
      const understanding = getUnderstandingStatus(statusKey);
      if (statusKey === NOT_ASSESSED) {
        finalGroup = uniqBy(group as QuestionCategorySummary[], QUESTION_CATEGORY_ID);
        isNotAssessed = true;
      } else {
        finalGroup = group;
      }
      const id = isNotAssessed ? 4 : statusToIdMap[statusKey as StatusKeyIdenf] || 4;

      let categoriesCovered =
        finalGroup
          ?.map((category) => ({
            id: category?.questionCategoryId,
            name: category?.questionCategoryName,
            categoryNumber: category?.categoryNumber || 0,
            status: category?.qcGapStatus || '',
          }))
          ?.filter(Boolean) || [];

      const categoriesCount = finalGroup?.length || 0;
      const totalCategoriesCount = questionCategorySummary?.length;

      categoriesCovered = orderBy(categoriesCovered, ['categoryNumber'], ['asc']);

      // Push data into `questionCategoryArray`
      if (finalGroup?.length > 0) {
        questionCategoryArray.push({
          chapterId,
          id,
          text: understanding?.text,
          categoriesCovered,
          categoriesCount,
          totalCategoriesCount,
          rating: understanding?.stars,
          interventionTypes: understanding?.interventions,
          color: understanding?.badgeColor,
        });
      }

      // Push connection data for non-NOT_ASSESSED statuses
      if (!isNotAssessed && finalGroup?.length > 0) {
        connectionData.push({
          id,
          text: understanding?.text,
          categoriesCount,
          totalCategoriesCount: questionCategorySummary?.length || 0,
          rating: understanding?.stars,
          interventionTypes: understanding?.interventions,
        });
      }
    });
  });
  return { questionCategoryArray, connectionData };
};

export const getCategoryEntity = (
  qcSummaryOfThisChapter: QuestionCategorySummary[] | undefined,
  stepLevelAnswers: StepLevelAnswers[],
  chapterId: string,
) => {
  if (!stepLevelAnswers?.length) return [];

  const categoryEntity: CategoryEntity[] = [];

  stepLevelAnswers.forEach((stepLevelAnswer) => {
    const { questionsScoreForEachSteps, questionCategoryId, questionCategoryName } =
      stepLevelAnswer;
    if (!questionsScoreForEachSteps?.length) return;
    const groupedByQuestionId = groupBy(questionsScoreForEachSteps, 'questionId');
    const totalNoOfQuestions = Object.keys(groupedByQuestionId)?.length;
    let questionsAnsweredCorrectly = 0;
    let questionNotAttempted = 0;
    for (const questionId in groupedByQuestionId) {
      if (groupedByQuestionId?.[questionId]?.every((record) => record?.questionScore === CORRECT)) {
        questionsAnsweredCorrectly++;
      }

      if (
        groupedByQuestionId?.[questionId]?.every(
          (record) => record.questionScore === STUDENT_COULD_NOT_SOLVE,
        )
      ) {
        questionNotAttempted++;
      }
    }

    const categoryNumber =
      qcSummaryOfThisChapter?.find((record) => record?.questionCategoryId === questionCategoryId)
        ?.categoryNumber || 0;

    categoryEntity.push({
      chapterId,
      categoryId: questionCategoryId,
      title: questionCategoryName,
      categoryNumber,
      questionsAnsweredCorrectly: questionsAnsweredCorrectly,
      questionNotAttempted: questionNotAttempted,
      totalQuestions: totalNoOfQuestions,
      incorrectAnswer: totalNoOfQuestions - (questionsAnsweredCorrectly + questionNotAttempted),
    });
  });

  const sortedCategoryEntities = orderBy(categoryEntity, ['categoryNumber'], ['asc']);

  return sortedCategoryEntities;
};
export const getQuestionsEntity = (
  qcSummaryOfThisChapter: QuestionCategorySummary[] | undefined,
  stepLevelAnswers: StepLevelAnswers[],
  chapterId: string,
) => {
  if (!stepLevelAnswers?.length) return [];

  const questionsEntity: QuestionEntity[] = [];

  stepLevelAnswers?.forEach((stepLevelAnswer) => {
    const { questionsScoreForEachSteps, questionCategoryId, questionCategoryName } =
      stepLevelAnswer;
    if (!questionsScoreForEachSteps?.length) return;
    const groupedByQuestionId = groupBy(questionsScoreForEachSteps, 'questionId');

    const categoryNumber =
      qcSummaryOfThisChapter?.find((record) => record?.questionCategoryId === questionCategoryId)
        ?.categoryNumber || 0;

    Object.entries(groupedByQuestionId).forEach(([questionId, steps]) => {
      // Derive the question's status (Correct if all steps have "correct" questionScore)
      const isCorrect = steps?.every((step) => step?.questionScore === CORRECT);
      const isDraft = steps?.some((step) => step?.questionScore === DRAFT);
      const studentCouldNotSolve = steps?.some(
        (step) => step?.questionScore === STUDENT_COULD_NOT_SOLVE,
      );
      const status = isCorrect
        ? CORRECT_STARTCASE
        : studentCouldNotSolve
          ? NOT_ATTEMPTED_STARTCASE
          : isDraft
            ? NOT_EVALUATED
            : INCORRECT_STARTCASE;

      // Create stepsRequired array with stepName and stepStatus
      const stepsRequired = steps
        ?.map((step) => ({
          stepId: step?.stepId,
          step: step?.stepName,
          status: step?.stepScore,
          stepStatus: step?.stepStatus,
        }))
        ?.sort((a, b) => a.stepId.localeCompare(b.stepId));

      // Collect notebookImages from all steps
      const notebookImages = steps?.[0]?.image || [];

      // Derive question string from questionDescription
      const question = steps?.[0]?.questionDescription || '';

      // Push the question entity to the result
      questionsEntity.push({
        questionId,
        chapterId,
        categoryId: questionCategoryId,
        categoryName: questionCategoryName,
        categoryNumber,
        question,
        stepsRequired,
        status,
        notebookImages,
      });
    });
  });

  return questionsEntity;
};

export const getDotColor = (evaluationId: number) => {
  switch (evaluationId) {
    case 1:
      return 'var(--semantic-green-600)';
    case 2:
      return 'var(--orange-400)';
    case 3:
      return 'var(--semantic-red-600)';
    case 4:
      return 'var(--neutral-500)';
    default:
      return '#000000';
  }
};

export const getHeaderBgColor = (id: number) => {
  switch (id) {
    case 1:
      return 'from-[#B6E6A0] to-[#CEF1BF]';
    case 2:
      return 'from-[#EDBE97] to-[#FBE2C6]';
    case 3:
      return 'from-[#F4B6B6] to-[#FBDCDC]';
    case 4:
      return 'from-neutral-400 to-neutral-200';
    default:
      return 'from-green-400 to-green-200';
  }
};

export const getHeaderTextColor = (id: number) => {
  switch (id) {
    case 1:
      return 'semantic-green-900';
    case 2:
      return 'orange-950';
    case 3:
      return 'semantic-red-900';
    case 4:
      return 'neutral-900';
    default:
      return '#000000';
  }
};
