import { useCallback, useContext, useEffect, useState } from 'react';

import { usePostEvaluationMutation } from 'store/apiSlices/inClass/evaluation.apiSlice';
import RenderEvalContent from '../RenderEvalContent';
import { FeedbackItem, IOptionSelection, IPolls } from 'types';
import useMeeting from 'hooks/inClass/useMeeting';
import './styles.css';
import { useSocketSubscribe, useInclassLogger, useNotification } from 'hooks';
import { SocketContext } from 'contexts';
import { BouncingDotsLoader } from 'components/common';
import EvalSubmissionModal from '../ModalInClass/EvalSubmissionModal';
import { selectMyCurrentState } from 'store/slice/inClassConfig.slice';
import { useAppSelector } from 'hooks/store';
import { EvaluationData } from 'types/inClass/lecture';
import { selectMultiLecture } from 'store/slice/lecture.slice';
import { currentLectureId, selectEvalId } from 'store/slice/content.slice';

const Polls = ({ polls, loading }: IPolls) => {
  // State variables
  const [selections, setSelections] = useState<IOptionSelection>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<FeedbackItem[]>([]);
  const [evalSubmissionModal, setEvalSubmissionModal] = useState<boolean>(false);
  const [pollQuestionsData, setPollQuestionsData] = useState<EvaluationData[] | undefined>([]);

  // Hooks
  const { tutors, classId, studentId, studentName, self } = useMeeting();
  const socket = useContext(SocketContext);
  const { datadogLog } = useInclassLogger();
  const triggerNotification = useNotification();

  // Redux
  const myCurrentState = useAppSelector(selectMyCurrentState) || 'teach';
  const lectureData = useAppSelector(selectMultiLecture);
  const evalId = useAppSelector(selectEvalId);
  const activeLectureId = useAppSelector(currentLectureId);

  // API Calls
  const [postEval, { isLoading: postEvalLoading, isSuccess: postEvalSuccess }] =
    usePostEvaluationMutation();

  useEffect(() => {
    socket?.emit('refreshHuddleState', {
      studentId: self?.customParticipantId,
      classId: classId,
    });
  }, [classId, self?.customParticipantId, socket]);

  useEffect(() => {
    if (polls) {
      lectureData?.[myCurrentState]?.map((lecture) => {
        if (lecture.type === 'evaluation' && lecture.evaluationId === evalId) {
          setPollQuestionsData(lecture.questions);
        }
      });
    }
  }, [lectureData, evalId, myCurrentState, polls]);

  // Handle Submit
  const handleSubmit = useCallback(
    async (isEndAction = false) => {
      const newFeedback: FeedbackItem[] = [];
      const response =
        pollQuestionsData?.map((question, index) => {
          const chosenOptionId = selections[question._id]?.optionId;
          const chosenOptionContent = selections[question._id]?.content || 'N/A';
          const correctOptionId = question.correct_option[0].content || 'N/A';
          const correctOptionContentArray = question[correctOptionId];
          const correctOptionContent = Array.isArray(correctOptionContentArray)
            ? correctOptionContentArray[0]?.content
            : 'N/A';
          const isCorrect = chosenOptionId === correctOptionId;
          const questionContent = question.question_description;
          const questionNumber = index + 1;

          newFeedback.push({
            questionId: question._id,
            questionNumber: questionNumber.toString(),
            questionContent: questionContent,
            chosenOptionId: chosenOptionId,
            feedback: isCorrect ? 'Correct' : `${correctOptionContent}`,
          });
          if (isEndAction) {
            return {
              elementId: question.elementId,
              questionId: question._id,
              score: isCorrect ? 'correct' : 'incorrect',
              selectedOptionsAndValues: {
                selectedOption: chosenOptionId,
                selectedOptionValue: chosenOptionContent,
                correctOption: correctOptionId,
                correctOptionValue: correctOptionContent,
              },
            };
          }

          return {
            elementId: question.elementId,
            questionId: question._id,
            score: isCorrect ? 'correct' : 'incorrect',
            selectedOptionsAndValues: {
              selectedOption: chosenOptionId,
              selectedOptionValue: chosenOptionContent,
              correctOption: correctOptionId,
              correctOptionValue: correctOptionContent,
            },
          };
        }) || [];
      setFeedback(newFeedback);

      const validStudentId = studentId || 0;
      const validClassId = classId || 0;

      if (validStudentId && validClassId) {
        const evalData = {
          studentId: validStudentId,
          classId: validClassId,
          evaluateAt: 'teach',
          evaluationId: evalId,
          lectureConfigurationId: activeLectureId,
          tutorId: Number(tutors[0]?.customParticipantId?.split('-').pop()),
          evaluationType: 'poll_evaluations',
          response: response.filter((r) => r !== null) as {
            elementId: string;
            questionId: string;
            score: string;
          }[],
          evaluationLevel: null,
        };
        try {
          await postEval({
            body: evalData,
          }).unwrap();
          triggerNotification('Polls submitted successfully', 'success');
          if (socket) {
            socket.emit('pollSubmitted', {
              studentId: studentId,
              evalId: evalId,
              studentName: studentName,
            });
            datadogLog(`${studentName} submitted poll`);
          }
        } catch (err) {
          console.error(err);
        } finally {
          setIsSubmitted(true);
        }
      }
    },
    [
      pollQuestionsData,
      studentId,
      classId,
      selections,
      evalId,
      activeLectureId,
      tutors,
      postEval,
      triggerNotification,
      socket,
      studentName,
      datadogLog,
    ],
  );

  const sendAnswer = (questionId: string, isCorrect: boolean, chosenOptionId: string) => {
    if (socket) {
      socket.emit('pollAnswerSelected', {
        studentId: studentId,
        evalId,
        questionId,
        isCorrect,
        chosenOptionId,
      });
      datadogLog(
        `${studentName} selected option ${chosenOptionId} for question ${questionId}, ${isCorrect}`,
      );
    }
  };

  // Handle Poll Restart
  const restartPollHandler = useCallback(
    (data: { evalId: string; currentState: string }) => {
      if (data.evalId === evalId && data.currentState === myCurrentState) {
        setSelections({});
        setIsSubmitted(false);
        setFeedback([]);
        triggerNotification('Tutor restarted polls');
      }
    },
    [evalId, myCurrentState, triggerNotification],
  );
  // Subscribe to "pollRestartToClient" socket messages
  useSocketSubscribe<{ evalId: string; currentState: string }>(
    'pollRestartToClient',
    restartPollHandler,
  );

  const evalToClientHandler = useCallback(
    (data: string) => {
      const parsedData = JSON.parse(data);
      if (
        evalId === parsedData.evalId &&
        parsedData.evalType === 'poll_evaluations' &&
        parsedData.submit === true
      ) {
        if (!postEvalSuccess) {
          handleSubmit();
        }
      }
    },
    [evalId, handleSubmit, postEvalSuccess],
  );

  useSocketSubscribe<string>('evaluationToClient', evalToClientHandler);

  const totalQuestions = pollQuestionsData?.length || 0;
  const answeredQuestions = Object.keys(selections).length;

  if (loading) {
    return (
      <div className='flex flex-col items-center justify-center text-white size-full'>
        <p className='mb-8 text-xl'>Waiting for tutor to start evaluation...</p>
        <BouncingDotsLoader />
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col justify-between size-full'>
        {!isSubmitted ? (
          <>
            <div className='relative overflow-y-scroll pollScrollbar'>
              <div className='sticky top-0 flex justify-between w-full py-5 pl-5 pr-3 text-xl subHeading'>
                <span className='text-2xl font-semibold text-white'>
                  Please answer the questions
                </span>
                <span
                  className={`py-1 px-3 text-white rounded-md ${answeredQuestions === totalQuestions ? 'bg-green-600' : 'bg-yellow-600'}`}
                >
                  {`Answered (${answeredQuestions} / ${totalQuestions})`}
                </span>
              </div>
              {pollQuestionsData &&
                pollQuestionsData?.map((question, index) => (
                  <div className='px-5 ' key={question._id}>
                    <div className='p-5 my-4 text-white bg-primary-850 rounded-xl'>
                      <div className='flex text-2xl'>
                        <span>{index + 1}. </span>{' '}
                        <span className='ml-2'>
                          <RenderEvalContent data={question.question_description} />
                        </span>
                      </div>
                      {['option1', 'option2', 'option3', 'option4'].map((optionKey) => {
                        const optionData = question[optionKey];
                        if (Array.isArray(optionData)) {
                          return (
                            <div key={optionKey} className='flex items-center my-2'>
                              <input
                                type='radio'
                                id={`${question._id}-${optionKey}`}
                                name={question._id}
                                value={optionData[0]?.content}
                                onChange={(e) => {
                                  const chosenOptionId = optionKey;
                                  const correctOptionId =
                                    question.correct_option[0].content || 'N/A';
                                  const isCorrect = chosenOptionId === correctOptionId;
                                  setSelections({
                                    ...selections,
                                    [question._id]: {
                                      optionId: chosenOptionId,
                                      content: e.target.value,
                                    },
                                  });
                                  sendAnswer(question._id, isCorrect, chosenOptionId);
                                }}
                                checked={selections[question._id]?.optionId === optionKey}
                                disabled={isSubmitted}
                                className='w-5 h-5 mr-2'
                              />
                              <label
                                htmlFor={`${question._id}-${optionKey}`}
                                className='text-xl cursor-pointer'
                              >
                                <RenderEvalContent data={optionData} />
                              </label>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                ))}
            </div>
            <div className='absolute bottom-0 right-0'>
              <button
                onClick={() => {
                  if (totalQuestions !== answeredQuestions) {
                    setEvalSubmissionModal(true);
                  } else {
                    handleSubmit();
                  }
                }}
                disabled={isSubmitted || postEvalLoading || Object.keys(selections).length === 0}
                className={`p-3 m-5 text-white rounded ${
                  isSubmitted
                    ? 'bg-gray-500'
                    : 'bg-green-500 hover:bg-green-700 transition duration-300 ease-in-out'
                } ${Object.keys(selections).length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              >
                {postEvalLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </>
        ) : (
          <div className='overflow-y-scroll pollScrollbar'>
            <div className='flex flex-col justify-center mx-auto mb-5 text-white h-fit gap-y-2'>
              <div className='sticky top-0 w-full py-5 text-xl text-center subHeading'>
                <span className='text-white'>Please check your answer</span>
              </div>
              <div className='px-5 '>
                {feedback.map(
                  ({
                    questionId,
                    questionNumber,
                    questionContent,
                    chosenOptionId,
                    feedback: feedbackText,
                  }) => {
                    const questionData = pollQuestionsData?.find((q) => q._id === questionId);
                    const chosenOptionData =
                      questionData && Array.isArray(questionData[chosenOptionId])
                        ? questionData[chosenOptionId]
                        : [];
                    const correctOptionId =
                      questionData && questionData.correct_option[0].content
                        ? questionData.correct_option[0].content
                        : 'N/A';
                    const correctOptionData =
                      questionData && Array.isArray(questionData[correctOptionId])
                        ? questionData[correctOptionId]
                        : [];
                    return (
                      <div
                        key={questionId}
                        className='p-4 mb-4 rounded-lg shadow-md bg-primary-850'
                      >
                        <p className='flex items-center text-xl'>
                          <span className='font-semibold'>{questionNumber}.</span>
                          <span className='ml-2'>
                            <RenderEvalContent data={questionContent} />
                          </span>
                        </p>
                        <p className='mt-2'>
                          <span className='font-semibold'>Your answer:</span>{' '}
                          <RenderEvalContent data={chosenOptionData} />
                        </p>
                        <p className='mt-2'>
                          {feedbackText.includes('Correct') ? (
                            <span className='text-green-500'>{feedbackText}</span>
                          ) : (
                            <span className='text-red-500'>
                              Incorrect. The correct answer is{' '}
                              <RenderEvalContent data={correctOptionData} />
                            </span>
                          )}
                        </p>
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <EvalSubmissionModal
        evalSubmissionModal={evalSubmissionModal}
        setEvalSubmissionModal={setEvalSubmissionModal}
        handleSubmit={() => handleSubmit()}
      />
    </>
  );
};

export default Polls;
