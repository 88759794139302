import classNames from 'classnames';
import { TagProps } from 'types/reports';

const Tag: React.FC<TagProps> = ({ text, iconLeft, iconRight, className }) => {
  const baseClasses =
    'flex items-center justify-between gap-[4px] bg-neutral-50 rounded-[5px] px-[8px] py-[2px]';
  const textClasses = 'font-sora text-sm font-medium text-neutral-700';
  const imgClasses = 'w-[18px] h-[18px]';

  const additionalClasses = classNames(className, {
    'py-[1px]': className?.includes('badge'),
    'pl-[2px]': iconLeft,
    'border-[1px] border-neutral-400 bg-neutral-50': className?.includes('bordered'), // Bordered class
    'border-semantic-green-800 bg-semantic-green-50 p-1': className?.includes('success'), // Success variant
    'border-semantic-red-700 bg-semantic-red-50 p-1': className?.includes('error'), // Error variant
    'border-primary-700 bg-primary-100': className?.includes('primary'), // Primary variant
    'border-teal-600 bg-teal-150': className?.includes('secondary'), // Secondary variant
    'border-purple-600 bg-purple-100': className?.includes('purple'), // Accent purple
    'border-orange-900 bg-orange-100': className?.includes('orange'), // Accent orange
    'border-[#571414] bg-[#ffebeb]': className?.includes('brown'), // Accent orange
  });

  const textColorClasses = classNames({
    'text-semantic-green-700 !important': className?.includes('success'),
    'text-semantic-red-700 !important': className?.includes('error'),
    'text-primary-700 !important': className?.includes('primary'),
    'text-teal-800 !important': className?.includes('secondary'),
    'text-purple-800 !important': className?.includes('purple'),
    'text-orange-900 !important': className?.includes('orange'),
    'text-[#571415] !important': className?.includes('brown'),
  });

  return (
    <div className={classNames(baseClasses, additionalClasses)}>
      {iconLeft && <img src={iconLeft} alt='icon left' className={imgClasses} />}
      <span className={classNames(textClasses, textColorClasses)}>{text}</span>
      {iconRight && <img src={iconRight} alt='icon right' className={imgClasses} />}
    </div>
  );
};

export default Tag;
