// Footer.tsx
import classNames from 'classnames';
import { FOOTER_DATA } from 'configs';

export default function Footer({ classname = '' }: { classname?: string }) {
  return (
    <footer className={classNames(classname, 'w-full py-4 bg-neutral-850 md:py-6')}>
      <div className='flex flex-col items-center justify-between px-6 md:flex-row'>
        <img src={FOOTER_DATA?.logo} alt='logo' />
        <nav className='flex flex-col flex-wrap items-center pt-6 text-sm text-white capitalize body-regular md:pt-0 md:flex-row'>
          {FOOTER_DATA?.data?.map((item, index) => (
            <a
              href={item?.link}
              key={index}
              className='py-2 pr-2 md:px-4 md:py-0'
              dangerouslySetInnerHTML={{ __html: item?.name }}
            ></a>
          ))}
        </nav>
      </div>
    </footer>
  );
}
