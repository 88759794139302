export const Common = {
  GOOGLE_SLIDES_DOMAIN: 'docs.google.com',
  SUBJECT_NAMES: {
    1: 'Maths',
    2: 'Science',
  },
};

export const SANITY_EPIC_SEARCH_NAMES = {
  CHAPTERS: 'CHAPTERS',
  SUBJECTS: 'SUBJECTS',
  QUESTION_CATEGORIES: 'QUESTION_CATEGORIES',
  BOOKS: 'BOOKS',
};
