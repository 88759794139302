import { useGetSanityEpicSearchQuery } from 'store/apiSlices/common.apiSlice';

export const useSanityOptions = (
  model: string,
  filters: { [key: string]: string | number | string[] | number[] } = { q: '' },
  skip: boolean = false,
) => {
  const queryParams = { model, ...filters };
  const { data, isLoading, isFetching, isError } = useGetSanityEpicSearchQuery(queryParams, {
    skip,
  });

  const options =
    data?.docs?.map((item) => ({
      label: item.name,
      value: item._id,
    })) ?? [];

  return { options, isLoading, isFetching, data, isError };
};
