import { datadogLogs } from '@datadog/browser-logs';
import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import { DyteDialogManager } from '@dytesdk/react-ui-kit';

import { NotificationProvider } from 'components/common/NotificationProvider';
import Meeting from 'components/inClass/Meeting';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import getCurrentState from 'utils/inClass/inClassFunctions';

import { BouncingDotsLoader, SocketProvider } from 'components/common';

import { USER_TYPE } from 'configs';
import { setMyCurrentState } from 'store/slice/inClassConfig.slice';
import { useAppDispatch } from 'hooks/store';
import PreClassScreen from 'components/inClass/PreClassScreen';
import { PreClassErrorScreen } from 'components/inClass/PreClassErrorScreen';

export default function StudentClassroom() {
  const dispatch = useAppDispatch();
  const [meetingToken, setMeetingToken] = useState<string>('');
  const [flag, setFlag] = useState<boolean>(false);
  // const { classId, studentId } = useMeeting();
  const { id } = useParams();
  const authToken = String(id);
  const [classId, setClassId] = useState<string>('');
  // API Call -> Get the meeting token
  const {
    data: joinClassRoomData,
    isSuccess,
    error,
    isFetching,
  } = useGetClassJoinTokenQuery({
    authToken,
  });

  useEffect(() => {
    if (isSuccess) {
      setClassId(String(joinClassRoomData?.data?.classId));
    }
  }, [isSuccess, joinClassRoomData?.data]);

  // Initialize the meeting
  const [meeting, initMeeting] = useDyteClient();

  // Asking for user media
  useEffect(() => {
    //Set User for datadog RUM
    if (joinClassRoomData?.data?.studentId) {
      datadogRum.setUser({
        id: joinClassRoomData?.data?.studentId?.toString(),
        userType: USER_TYPE.STUDENT,
      });
    }

    const getMedia = async () => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return;
      }
      try {
        const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: 1280, height: 720 },
        });
        stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        setTimeout(() => {
          setFlag(false);
        }, 2000);
        datadogLogs.logger.info(
          `User media permissions granted and media devices checked for student with studentId:${joinClassRoomData?.data?.studentId} and classId:${joinClassRoomData?.data?.classId}`,
          {
            classId: joinClassRoomData?.data?.classId,
            studentId: joinClassRoomData?.data?.studentId,
          },
        );
      } catch (err) {
        console.error(err);
        datadogLogs.logger.error(
          `Error accessing user media devices for student with studentId:${joinClassRoomData?.data?.studentId} and classId:${joinClassRoomData?.data?.classId}`,
          {
            error: err,
            classId: joinClassRoomData?.data?.classId,
            studentId: joinClassRoomData?.data?.studentId,
          },
        );
        setTimeout(() => {
          setFlag(false);
        }, 2000);
      }
    };
    // Ask for media only if all the meetingToken is available
    if (
      joinClassRoomData?.data?.classId &&
      joinClassRoomData?.data?.studentId &&
      joinClassRoomData?.data?.meetingToken
    ) {
      setFlag(true);
      getMedia();
    }
  }, [
    joinClassRoomData?.data?.classId,
    joinClassRoomData?.data?.studentId,
    joinClassRoomData?.data?.meetingToken,
  ]);

  // When the query is successful, set the meeting token
  useEffect(() => {
    if (isSuccess && joinClassRoomData) {
      setMeetingToken(joinClassRoomData?.data?.meetingToken);
    }
  }, [isSuccess, joinClassRoomData]);

  // When the meeting token changes, initialize the meeting
  useEffect(() => {
    if (meetingToken) {
      initMeeting({
        authToken: meetingToken,
        defaults: {
          audio: true,
          video: true,
        },
      })
        .then(async (meet) => {
          if (meet && meet?.self?.customParticipantId) {
            const currentStatus = await getCurrentState(String(classId));
            const selectedStudentStatus = currentStatus?.data.find(
              (data: { studentId: number | undefined }) =>
                data.studentId === joinClassRoomData?.data?.studentId,
            );
            if (selectedStudentStatus?.currentState) {
              dispatch(setMyCurrentState(selectedStudentStatus?.currentState));
            }
            datadogLogs.logger.info(
              `Socket connected for student with studentId:${joinClassRoomData?.data?.studentId} and classId:${joinClassRoomData?.data?.classId}`,
              {
                classId: joinClassRoomData?.data?.classId,
                studentId: joinClassRoomData?.data?.studentId,
              },
            );
          }
        })
        .catch((error) => {
          console.error('Error initializing the meeting', error);
          datadogLogs.logger.error('Error initializing the meeting', { error });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingToken]);

  if (flag || isFetching) {
    return (
      <div className='flex items-center justify-center h-[100vh] '>
        <BouncingDotsLoader />
      </div>
    );
  }

  return isSuccess ? (
    joinClassRoomData?.data?.inOngoingClass ? (
      <>
        <DyteProvider value={meeting}>
          <SocketProvider
            userId={joinClassRoomData?.data?.studentId?.toString()}
            userType='student'
            classId={joinClassRoomData?.data?.classId?.toString()}
          >
            <NotificationProvider>
              <DyteDialogManager meeting={meeting} />
              <Meeting />
            </NotificationProvider>
          </SocketProvider>
        </DyteProvider>
      </>
    ) : (
      <PreClassScreen
        classStartTime={joinClassRoomData?.data?.meetingStartTime}
        tutorDetails={joinClassRoomData?.data?.tutorDetails}
        subject={joinClassRoomData?.data?.subject}
        className={
          joinClassRoomData?.data?.lectureConfigurationName || joinClassRoomData?.data?.name
        }
      />
    )
  ) : (
    <PreClassErrorScreen error={error} />
  );
}
