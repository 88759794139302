import React, { useState } from 'react';
import classNames from 'classnames';
import { NotebookImageGalleryProps } from 'types/reports';
import { CrossIcon } from 'assets/svg';
import { Carousel } from 'flowbite-react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

const NotebookImageGallery: React.FC<NotebookImageGalleryProps> = ({
  notebookImages,
  className,
  onClose,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleSlideChange = (newIndex: number) => {
    setActiveIndex(newIndex);
  };
  return (
    <div
      className={classNames(
        'relative bg-white w-full h-full rounded-[24px] flex flex-col items-center overflow-hidden',
        className,
      )}
    >
      <div className='flex items-center justify-between bg-white p-[24px] w-full absolute z-10 shadow-[0px_16px_32px_-4px_#0c0c0d0d]'>
        <h4 className='text-neutral-950 font-sora font-bold text-base'>Notebook Images</h4>
        <img
          src={CrossIcon}
          alt='close popup'
          width={20}
          height={20}
          onClick={onClose}
          className='w-[20px] h-[20px] cursor-pointer'
        />
      </div>

      <div className='w-full bg-white p-4 lg:p-8'>
        <div className='relative w-full h-[500px] bg-gray-50 rounded-2xl overflow-hidden'>
          <Carousel
            slide={false}
            indicators={false}
            onSlideChange={handleSlideChange}
            leftControl={
              <button
                className={`absolute left-4 z-10 p-2 rounded-full bg-white/90 shadow-lg backdrop-blur-sm 
                hover:bg-white/100 transition-all duration-200 
                ${notebookImages.length <= 1 ? 'opacity-50 cursor-not-allowed' : 'hover:scale-110'}`}
                aria-label='Previous slide'
              >
                <FaChevronLeft className='w-6 h-6 text-gray-800' />
              </button>
            }
            rightControl={
              <button
                className={`absolute right-4 z-10 p-2 rounded-full bg-white/90 shadow-lg backdrop-blur-sm 
                hover:bg-white/100 transition-all duration-200 
                ${notebookImages.length <= 1 ? 'opacity-50 cursor-not-allowed' : 'hover:scale-110'}`}
                aria-label='Next slide'
              >
                <FaChevronRight className='w-6 h-6 text-gray-800' />
              </button>
            }
          >
            {notebookImages.map((image, idx) => (
              <div key={idx} className='relative h-[500px] w-full'>
                <img
                  src={image}
                  alt={`Slide ${idx + 1}`}
                  className='absolute inset-0 w-full h-full object-contain'
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className='absolute bottom-6 left-1/2 transform -translate-x-1/2 flex items-center gap-2 z-20'>
          {notebookImages.map((_, idx) => (
            <button
              key={idx}
              onClick={() => handleSlideChange(idx)}
              className={`w-2 h-2 rounded-full transition-all duration-300 
                ${idx === activeIndex ? 'bg-gray-800 w-6' : 'bg-gray-400 hover:bg-gray-600'}`}
              aria-label={`Go to slide ${idx + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotebookImageGallery;
