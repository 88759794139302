import React from 'react';

interface InfoCardProps {
  image: string; // Image URL
  title: string; // Title text
  description: string; // Description text
}

const InfoCard: React.FC<InfoCardProps> = ({ image, title, description }) => {
  return (
    <div className='flex flex-col bg-white rounded-[12px] shadow-lg p-2 w-full lg:w-[320px] z-40'>
      <div className='w-full h-[200px] overflow-hidden rounded-[12px]'>
        <img src={image} alt={title} className='w-full h-full object-cover' />
      </div>
      <div className='flex flex-col p-3'>
        <h2 className='text-xl font-semibold font-sora text-neutral-950 mb-2'>{title}</h2>
        <p className='text-sm font-normal text-neutral-400 leading-relaxed font-poppins'>
          {description}
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
