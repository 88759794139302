import { createContext, RefObject, MutableRefObject } from 'react';
import { CategoryEntity, QuestionCategoryEntity } from 'types/reports';

interface UnderstandingToCategoryConnectionContextType {
  selectedUnderstandingRef: RefObject<HTMLDivElement> | null;
  underStandingRefs: MutableRefObject<Array<HTMLDivElement | null>>;
  categoriesData: CategoryEntity[];
  selectedCategory: QuestionCategoryEntity | null;
}

export const UnderstandingToCategoryConnectionContext =
  createContext<UnderstandingToCategoryConnectionContextType>({
    selectedUnderstandingRef: null,
    underStandingRefs: { current: [] },
    categoriesData: [],
    selectedCategory: null,
  });
