import { FaChevronDown, FaChevronRight, FaCircleInfo } from 'react-icons/fa6';
import ComingSoonBadge from '../ComingSoonBadge';
import { examTypeReports, reportInfo } from 'configs/reports';
import { Tooltip } from 'flowbite-react';
import { MainContentProps } from 'types/reports';
import { MathIcon, ScienceIcon } from 'assets/svg';

const MainContent = ({
  toggleSection,
  isActive,
  handleMathsQcClick,
  expandedSections,
}: MainContentProps) => (
  <div className='h-full custom-scrollbar'>
    <div className='p-4 lg:pt-16 overflow-y-auto lg:pb-20'>
      {/* Chapterwise Reports Section */}
      <div className='mb-4 bg-white rounded-xl border border-gray-200/60 shadow-sm hover:shadow-md transition-all duration-200'>
        <div className='px-4 py-3 flex items-center gap-2 border-b border-gray-100 bg-gradient-to-r from-gray-50 to-white rounded-t-xl'>
          <span className='font-semibold font-sora text-base text-gray-900'>
            Chapterwise Reports
          </span>
          <Tooltip
            content={
              <div className='text-sm font-normal font-poppins cursor-pointer w-60'>
                {reportInfo.map((text, index) => (
                  <p key={index} className={index > 0 ? 'mt-2' : ''}>
                    {text}
                  </p>
                ))}
              </div>
            }
            color='foreground'
            placement='bottom-start'
          >
            <FaCircleInfo className='w-3.5 h-3.5 text-gray-400 hover:text-gray-600 cursor-help transition-colors' />
          </Tooltip>
        </div>

        <div>
          {/* Mathematics Section */}
          <div>
            <button
              onClick={() => toggleSection('mathematics')}
              className='w-full px-4 py-3 flex items-center justify-between text-left border-b border-gray-100 hover:bg-gray-50/50 transition-colors duration-200'
            >
              <div className='flex items-center justify-start space-x-2'>
                <div className='p-1.5 sm:p-2 rounded-full bg-blue-50'>
                  <span className='w-5 h-5 sm:w-6 sm:h-6'>
                    <img src={MathIcon} />
                  </span>
                </div>
                <span className='text-[15px] font-medium text-sm font-sora text-gray-900'>
                  Mathematics
                </span>
              </div>
              <div className='bg-gray-100 rounded-lg p-1'>
                {expandedSections.mathematics ? (
                  <FaChevronDown className='w-3.5 h-3.5 text-gray-500 transition-transform duration-200' />
                ) : (
                  <FaChevronRight className='w-3.5 h-3.5 text-gray-500 transition-transform duration-200' />
                )}
              </div>
            </button>

            {expandedSections.mathematics && (
              <div className='bg-gray-50/50'>
                <div
                  onClick={handleMathsQcClick}
                  className={`flex items-center justify-between px-4 py-3 cursor-pointer transition-all duration-200 ${
                    isActive('/chapter')
                      ? 'bg-indigo-50 border-l-4 border-indigo-600'
                      : 'hover:bg-gray-100/70 border-l-4 border-transparent'
                  }`}
                >
                  <span
                    className={`text-[14px] text-sm font-sora font-medium ${
                      isActive('/chapter') ? 'text-gray-900' : 'text-gray-700'
                    }`}
                  >
                    QC Report
                  </span>
                  <span
                    className={`text-sm font-sora ${
                      isActive('/chapter') ? 'text-gray-900' : 'text-gray-600'
                    }`}
                  >
                    Question Category Level
                  </span>
                </div>
                <div className='flex items-center justify-between px-4 py-3 hover:bg-gray-100/70 transition-colors duration-200'>
                  <span className='text-[14px] text-gray-300 text-sm font-sora'>
                    Intervention Report
                  </span>
                  <ComingSoonBadge />
                </div>
              </div>
            )}
          </div>

          {/* Science Section */}
          <div>
            <button
              onClick={() => toggleSection('science')}
              className='w-full px-4 py-3 flex items-center justify-between text-left border-t border-b border-gray-100 hover:bg-gray-50/50 transition-colors duration-200'
            >
              <div className='flex items-center justify-start space-x-2'>
                <div className='p-1.5 sm:p-2 rounded-full bg-pink-50'>
                  <span className='w-5 h-5 sm:w-6 sm:h-6'>
                    <img src={ScienceIcon} />
                  </span>
                </div>
                <span className='text-[15px] font-medium text-gray-900 text-sm font-sora'>
                  Science
                </span>
              </div>
              <div className='bg-gray-100 rounded-lg p-1'>
                {expandedSections.science ? (
                  <FaChevronDown className='w-3.5 h-3.5 text-gray-500 transition-transform duration-200' />
                ) : (
                  <FaChevronRight className='w-3.5 h-3.5 text-gray-500 transition-transform duration-200' />
                )}
              </div>
            </button>

            {expandedSections.science && (
              <div className='bg-gray-50/50'>
                <div className='flex items-center justify-between px-4 py-3 hover:bg-gray-100/70 transition-colors duration-200'>
                  <span className='text-[14px] text-gray-300 text-sm font-sora'>QC Report</span>
                  <ComingSoonBadge />
                </div>
                <div className='flex items-center justify-between px-4 py-3 hover:bg-gray-100/70 transition-colors duration-200'>
                  <span className='text-[14px] text-gray-300 text-sm font-sora'>
                    Intervention Report
                  </span>
                  <ComingSoonBadge />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Exam Readiness Reports Section */}
      <div className='bg-white rounded-xl border border-gray-200/60 shadow-sm hover:shadow-md transition-all duration-200'>
        <div className='px-4 py-3 border-b border-gray-100 bg-gradient-to-r from-gray-50 to-white rounded-t-xl'>
          <span className='font-semibold text-gray-900 text-base font-sora'>
            Exam Readiness Reports
          </span>
        </div>

        <div className='p-4 bg-white rounded-b-xl'>
          <div className='grid grid-cols-2 gap-4'>
            {examTypeReports.map((pt) => (
              <div
                key={pt}
                className='flex flex-col gap-1 p-3 rounded-lg bg-gray-50/50 hover:bg-gray-100/50 transition-all duration-200'
              >
                <div className='text-[14px] font-medium text-gray-300 text-sm font-sora'>{pt}</div>
                <ComingSoonBadge />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MainContent;
