import React from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Tag from '../Tag';
import { MobileQuestionCategoryCardProps } from 'types/reports';
import CategoryCard from '../CategoryCard';
import { PRACTICE, RE_EXPLAIN } from 'configs/reports';
import { getStatusClasses } from 'configs/studentReport';
import { CrossIcon, StarIcon } from 'assets/svg';

const MobileQuestionCategoryCard: React.FC<MobileQuestionCategoryCardProps> = ({
  questionCategory,
  categories,
  onClick,
  className,
}) => {
  const { chapterId, understandingLevel, studentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/a');

  const handleCategoryClick = (categoryId: string) => {
    const prefix = isAdminRoute ? 'a' : 'p';
    navigate(
      `/${prefix}/reports/${studentId}/chapter/${chapterId}/understanding/category/${understandingLevel}/${categoryId}`,
    );
  };

  return (
    <div
      className={classNames(
        "relative bg-white rounded-lg p-3 cursor-pointer before:content-[''] before:absolute before:top-3 before:bottom-3 before:left-[-2px] before:w-1 before:rounded-tr-lg before:rounded-br-lg",
        getStatusClasses(questionCategory.id),
        className,
      )}
    >
      <div className='flex flex-col gap-1'>
        <div className='flex flex-col gap-1'>
          <div className='flex justify-between items-center text-neutral-900 font-semibold text-2xl max-w-[97%]'>
            <div className='flex items-center gap-1'>
              <h2 className='text-neutral-950 text-sm font-semibold font-sora'>
                {questionCategory?.text}
              </h2>
            </div>
            <div className='flex items-center gap-2'>
              <Tag
                text={`${questionCategory?.categoriesCount}/${questionCategory?.totalCategoriesCount}`}
                className='success'
              />
              <img src={CrossIcon} onClick={onClick} alt='close icon' width={20} height={20} />
            </div>
          </div>

          <div className='flex gap-0 mb-2'>
            {Array.from({ length: questionCategory?.rating || 0 }, (_, i) => (
              <img src={StarIcon} width={24} height={24} alt='star' key={i} />
            ))}
          </div>

          <div className='flex items-center text-neutral-500 text-sm font-semibold gap-2'>
            <span className='font-medium'>Intervention:</span>
            {questionCategory?.interventionTypes &&
            questionCategory?.interventionTypes?.length > 0 ? (
              questionCategory?.interventionTypes?.map((interventionType, index) => (
                <Tag
                  text={interventionType}
                  key={index}
                  className={`bordered ${
                    interventionType?.toLocaleLowerCase() === PRACTICE
                      ? 'secondary'
                      : interventionType?.toLocaleLowerCase() === RE_EXPLAIN
                        ? 'primary'
                        : ''
                  }`}
                />
              ))
            ) : (
              <Tag text='N/A' key={0} className='bordered' />
            )}
          </div>
        </div>

        <div className='mt-5 flex flex-col gap-4'>
          {categories &&
            categories.map((category, idx) => (
              <div className='border-2 border-neutral-100 rounded-lg' key={idx}>
                <CategoryCard
                  key={category?.categoryId}
                  idx={idx}
                  category={category}
                  onClick={() => handleCategoryClick(category?.categoryId)}
                  evaluationId={questionCategory?.id}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MobileQuestionCategoryCard;
