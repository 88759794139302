import CategoryCard from 'components/screens/reports/CategoryCard';
import SectionHeader from 'components/screens/reports/SectionHeader';
import { evaluationsData } from 'configs/reports';
import { getDotColor, getHeaderBgColor, getHeaderTextColor } from 'configs/studentReport';
import { UnderstandingToCategoryConnectionContext } from 'contexts/UnderstandingToCategoryConnectionContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Connection } from 'types/reports';

const CategoryOutlet = () => {
  const { chapterId, categoryId, studentId, understandingLevel } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const categoryRefs = useRef<Array<HTMLDivElement | null>>([]);
  let colorStatusId = 0;
  const { selectedUnderstandingRef, underStandingRefs, categoriesData, selectedCategory } =
    useContext(UnderstandingToCategoryConnectionContext);

  if (selectedCategory) {
    colorStatusId = evaluationsData[Number(selectedCategory?.id) - 1].id;
  }
  const [connections, setConnections] = useState<Connection[]>([]);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const isAdminRoute = location.pathname.startsWith('/a');

  useEffect(() => {
    const calculateConnections = () => {
      if (!containerRef.current || !selectedUnderstandingRef?.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const underStandingRect = selectedUnderstandingRef.current.getBoundingClientRect();
      const newConnections: Connection[] = [];

      categoryRefs.current.forEach((categoryRef) => {
        if (!categoryRef) return;

        const categoryRect = categoryRef.getBoundingClientRect();

        // Updated coordinate calculations
        const startX = underStandingRect.right - containerRect.left;
        const startY = underStandingRect.top + underStandingRect.height / 2 - containerRect.top;
        const endX = categoryRect.left - containerRect.left;
        const endY = categoryRect.top + 32 - containerRect.top;

        const curveWidth = 40;
        const curveRadius = 30;
        const yDifference = Math.abs(endY - startY);
        const isDescending = endY > startY;
        const minLineHeight = 40; // Ensure a minimum height for the line

        const isNearlyHorizontal = yDifference < minLineHeight;

        let startCurve, endCurve, line;

        if (isNearlyHorizontal) {
          // For nearly horizontal connections, use a simple straight line with small curves
          startCurve = ``;

          endCurve = `
            M ${endX - curveWidth} ${endY}
            H ${endX}
          `;

          line = {
            top: startY - 1,
            left: startX + curveWidth - 1,
            height: 2, // Minimal height for visual consistency
          };
        } else {
          startCurve = `
            M ${startX} ${startY}
            H ${startX + curveWidth - curveRadius}
            Q ${startX + curveWidth} ${startY},
              ${startX + curveWidth} ${startY + (isDescending ? curveRadius : -curveRadius)}
            V ${isDescending ? endY - curveRadius : endY + curveRadius}
          `;

          endCurve = `
            M ${endX - curveWidth} ${isDescending ? endY - curveRadius : startY - curveRadius}
            V ${endY - (isDescending ? curveRadius : -curveRadius)}
            Q ${endX - curveWidth} ${endY},
              ${endX - curveWidth + curveRadius} ${endY}
            H ${endX}
          `;

          line = {
            top: Math.min(
              startY + (isDescending ? curveRadius - 1 : 0),
              endY + (isDescending ? 0 : curveRadius - 1),
            ),
            left: startX + curveWidth - 1,
            height: Math.abs(endY - startY) - curveRadius * 2 + 2,
          };
        }

        newConnections.push({
          startCurve,
          endCurve,
          line,
          startDot: { x: startX, y: startY },
          endDot: { x: endX, y: endY },
          colorStatusId: colorStatusId ?? 1,
        });
      });

      setConnections(newConnections);
      setSvgDimensions({
        width: containerRect.width,
        height: containerRect.height,
      });
    };

    calculateConnections();
    const observer = new ResizeObserver(calculateConnections);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    if (underStandingRefs?.current) {
      underStandingRefs.current.forEach((ref) => {
        if (ref === selectedUnderstandingRef?.current && selectedUnderstandingRef?.current) {
          observer.observe(selectedUnderstandingRef.current);
        }
      });
    }

    window.addEventListener('scroll', calculateConnections);
    window.addEventListener('resize', calculateConnections);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', calculateConnections);
      window.removeEventListener('resize', calculateConnections);
    };
  }, [underStandingRefs, selectedUnderstandingRef, colorStatusId]);

  const handleCategoryClick = (categoryId: string) => {
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else if (categoryRefs.current) {
      window.scrollTo({
        top: 20,
        behavior: 'smooth',
      });
    }
    const prefix = isAdminRoute ? '/a' : '/p';
    navigate(
      `${prefix}/reports/${studentId}/chapter/${chapterId}/understanding/category/${understandingLevel}/${categoryId}`,
    );
  };

  const filteredCategories =
    categoriesData?.filter((category) => {
      const { categoryId } = category;
      const coveredCategories = selectedCategory?.categoriesCovered || [];
      return coveredCategories.some((coveredCategory) => coveredCategory?.id === categoryId);
    }) || [];

  return (
    <>
      {!filteredCategories?.length ? null : (
        <div ref={containerRef} className='relative'>
          <div
            className={`flex ${categoryId ? 'w-[1150px]' : 'w-full'} transition-all duration-300`}
          >
            <div className='flex flex-col '>
              <SectionHeader
                text='Question category level understanding'
                textColor={getHeaderTextColor(colorStatusId ?? 1)}
                backgroundColor={getHeaderBgColor(colorStatusId ?? 1)}
              />
              <div className={`flex flex-col gap-4 w-[400px] `}>
                {filteredCategories.map((category, idx) => (
                  <div key={category.categoryId} ref={(el) => (categoryRefs.current[idx] = el)}>
                    <CategoryCard
                      idx={idx}
                      category={category}
                      evaluationId={colorStatusId}
                      onClick={() => handleCategoryClick(category?.categoryId)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Connection Elements */}
          <div className='absolute inset-0 pointer-events-none ' style={{ zIndex: 0 }}>
            <svg
              className='absolute top-0 left-0'
              width={svgDimensions.width}
              height={svgDimensions.height}
              style={{ overflow: 'visible' }}
            >
              {connections.map((conn, index) => (
                <g key={`curves-${index}`}>
                  {/* Connection Curves */}
                  <path
                    d={conn.startCurve}
                    fill='none'
                    stroke='#B4B6C5'
                    strokeWidth='2'
                    strokeLinecap='round'
                    className='transition-all duration-300'
                  />
                  <path
                    d={conn.endCurve}
                    fill='none'
                    stroke='#B4B6C5'
                    strokeWidth='2'
                    strokeLinecap='round'
                    className='transition-all duration-300'
                  />
                  {/* Start Half-Cycle Dot */}
                  <path
                    d={`M ${conn.startDot.x},${conn.startDot.y - 5} 
                    A 5,5 0 1,1 ${conn.startDot.x},${conn.startDot.y + 5}`}
                    fill={getDotColor(conn.colorStatusId ?? 1)}
                  />
                  {/* End Half-Cycle Dot */}
                  <path
                    d={`M ${conn.endDot.x},${conn.endDot.y - 5} 
                    A 5,5 0 1,0 ${conn.endDot.x},${conn.endDot.y + 5}`}
                    fill={getDotColor(conn.colorStatusId ?? 1)}
                  />
                </g>
              ))}
            </svg>

            {connections.map(
              (conn, index) =>
                conn.line && (
                  <div
                    key={`line-${index}`}
                    className='absolute bg-neutral-300 w-0.5 transition-all duration-300'
                    style={{
                      top: `${conn.line.top}px`,
                      left: `${conn.line.left}px`,
                      height: `${conn.line.height}px`,
                    }}
                  />
                ),
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryOutlet;
