import React, { useEffect } from 'react';
import classNames from 'classnames';

export interface BottomSheetProps {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ isVisible, onClose, children }) => {
  // Prevent body scroll when bottom sheet is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div
      className={classNames(
        'fixed inset-0 bg-black/50 z-50 transition-all duration-300 ease-in-out',
        { 'opacity-1 visible': isVisible, 'opacity-0 invisible': !isVisible },
      )}
      onClick={onClose}
    >
      <div
        className={classNames(
          'fixed left-0 right-0 bottom-0 w-full transform transition-transform duration-300 ease-in-out',
          {
            'translate-y-0': isVisible,
            'translate-y-full': !isVisible,
          },
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className='flex justify-center py-2'>
          <div className='w-10 h-[2px] rounded-full bg-neutral-200'></div>
        </div>
        <div className='bg-white rounded-t-[12px] w-full min-h-[200px] max-h-[90vh] overflow-y-auto'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BottomSheet;
