import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import BottomSheet from '../BottomSheet';
import { ChapterCardProps, EVAL_TYPE } from 'types/reports';
import InfoCard from '../InfoCard';
import {
  InfoIconBlue,
  NotebookIcon,
  NotebookImage,
  PollImage,
  PracticeIcon,
  PracticesheetImage,
  RedirectIcon,
} from 'assets/svg';
import {
  NOTEBOOK,
  NOTEBOOK_GENERAL_QUES,
  NOTEBOOK_QUES_ANS,
  PRACTICE,
  PRACTICE_GENERAL_QUES,
  PRACTICE_QUES_ANS,
} from 'configs/reports';
import { useWindowDimensions } from 'hooks';
import Cookie from 'js-cookie';

const ChapterCard: React.FC<ChapterCardProps> = ({ chapter, isSelected = false, onClick }) => {
  const { isMobileScreen } = useWindowDimensions();
  const [infoContent, setInfoContent] = useState<{
    title: string;
    description: string;
    position?: { top: number; left: number };
    type: EVAL_TYPE;
  } | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(isMobileScreen);
  const [bottomSheetVisible, setBottomSheetVisible] = useState<boolean>(false);
  const [cardPosition, setCardPosition] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  } | null>(null);

  const isImpersonatedUser = Cookie.get(`isImpersonatedUser`) === 'true';

  useEffect(() => {
    setIsMobile(isMobileScreen);
  }, [isMobileScreen]);

  const handleInfoClick = (
    e: React.MouseEvent<HTMLImageElement>,
    title: string,
    description: string,
    type: EVAL_TYPE,
  ) => {
    e.stopPropagation();
    const rect = e.currentTarget.closest(`.chapter-card`)?.getBoundingClientRect();

    if (rect) {
      setCardPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      });
    }

    if (isMobile) {
      setInfoContent({ title, description, type });
      setBottomSheetVisible(true);
    } else {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const infoCardWidth = 400; // Assuming the info card has a width of 400px
      const infoCardHeight = 200; // Assuming the info card has a height of 200px

      const left = (windowWidth - infoCardWidth) / 2;
      const top = (windowHeight - infoCardHeight) / 2;

      setInfoContent({
        title,
        description,
        position: {
          top,
          left,
        },
        type,
      });
    }
  };

  const handleInfoCardClose = () => {
    setInfoContent(null);
    setCardPosition(null);
  };

  const handleBottomSheetClose = () => {
    setBottomSheetVisible(false);
    setInfoContent(null);
  };

  const getImageForType = (type: EVAL_TYPE) => {
    return type === NOTEBOOK ? NotebookImage : type === PRACTICE ? PracticesheetImage : PollImage;
  };

  return (
    <>
      <div
        className={classNames(
          'relative bg-white rounded-[12px] border-[2px] border-transparent p-3 transition-all duration-200 ease-in-out',
          {
            'hover:lg:border-primary-100': !isSelected,
            'lg:border-primary-300 border-solid shadow-[0_16px_32px_-4px_rgba(12,12,13,0.1)]':
              isSelected,
            'relative before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-[-2px] before:w-1 before:rounded-r before:bg-primary-700':
              isSelected,
            'relative z-[200] shadow-[0_16px_32px_-4px_rgba(12,12,13,0.1)] transition-all duration-300 ease-in-out':
              infoContent && !isMobile,
          },
        )}
        onClick={handleInfoCardClose}
      >
        <div className='mb-3 cursor-pointer' onClick={onClick}>
          <div className='flex items-center justify-between text-neutral-950 mb-1 font-sora text-sm font-semibold'>
            <h2 className='text-sm font-sora font-semibold'>{chapter?.chapterName}</h2>
            <img src={RedirectIcon} alt='redirect icon' width={20} height={20} />
          </div>
          {isImpersonatedUser ? (
            <span
              className={`font-normal uppercase px-2 py-[1px] rounded-[24px] text-xs !font-roboto ${
                chapter?.reportStatus === 'draft'
                  ? 'bg-pink-100 text-pink-800'
                  : 'text-semantic-green-700 bg-semantic-green-100'
              }`}
            >
              {chapter?.reportStatus}
            </span>
          ) : null}
          <p className='text-xs font-poppins font-medium text-neutral-300'>{chapter?.dateRange}</p>
        </div>

        <div className='flex flex-col gap-3 p-2 bg-neutral-50 rounded-lg'>
          {!chapter?.notebookEvaluations ? null : (
            <div className='flex items-center text-xs font-medium font-poppins text-neutral-300'>
              <div className='w-8 h-8 p-2 rounded-full border border-neutral-100 mr-2'>
                <img src={NotebookIcon} alt='notebook icon' className='w-4 h-4' />
              </div>
              <span className='text-neutral-600 mr-1 text-sm'>Notebook evaluations:</span>
              <span className='text-sm font-semibold font-poppins text-neutral-900'>
                {chapter?.notebookEvaluations}
              </span>
              <img
                src={InfoIconBlue}
                alt='info icon'
                className='ml-2 cursor-pointer'
                width={20}
                height={20}
                onClick={(e) =>
                  handleInfoClick(e, NOTEBOOK_GENERAL_QUES, NOTEBOOK_QUES_ANS, NOTEBOOK)
                }
              />
            </div>
          )}

          {/* {!chapter.pollEvaluation ? null : (
            <div className='flex items-center text-xs font-medium font-poppins text-neutral-300'>
              <div className='w-8 h-8 p-2 rounded-full border border-neutral-100 mr-2'>
                <img src={practiceIcon} alt='practice icon' className='w-4 h-4' />
              </div>
              <span className='text-sm text-neutral-600 mr-1'>Poll Evaluations:</span>
              <span className='text-sm font-semibold font-poppins text-neutral-900'>
                {chapter.pollEvaluation}
              </span>
              <img
                src={infoIcon}
                alt='info icon'
                className='ml-2 cursor-pointer'
                width={20}
                height={20}
                onClick={(e) => handleInfoClick(e, POLL_GENERAL_QUES, POLL_QUES_ANS, POLL)}
              />
            </div>
          )} */}

          {!chapter?.practiceSheets ? null : (
            <div className='flex items-center text-xs font-medium font-poppins text-neutral-300'>
              <div className='w-8 h-8 p-2 rounded-full border border-neutral-100 mr-2'>
                <img src={PracticeIcon} alt='practice icon' className='w-4 h-4' />
              </div>
              <span className='text-sm text-neutral-600 mr-1'>Practice Sheets:</span>
              <span className='text-sm font-semibold font-poppins text-neutral-900'>
                {chapter?.practiceSheets}
              </span>
              <img
                src={InfoIconBlue}
                alt='info icon'
                className='ml-2 cursor-pointer'
                width={20}
                height={20}
                onClick={(e) =>
                  handleInfoClick(e, PRACTICE_GENERAL_QUES, PRACTICE_QUES_ANS, PRACTICE)
                }
              />
            </div>
          )}
        </div>

        {infoContent &&
          !isMobile &&
          infoContent?.position &&
          createPortal(
            <div className='fixed inset-0 z-[105]'>
              <div
                className='fixed inset-0 bg-black/50 transition-opacity duration-300'
                onClick={handleInfoCardClose}
              />
              {cardPosition && (
                <div
                  className='fixed bg-white shadow-lg z-[110] rounded-lg'
                  style={{
                    top: cardPosition.top,
                    left: cardPosition.left,
                    width: cardPosition.width,
                    height: cardPosition.height,
                    borderRadius: 12,
                  }}
                  onClick={handleInfoCardClose}
                >
                  <div
                    className={classNames(
                      'relative bg-white rounded-[12px] border-[2px] border-transparent p-3 transition-all duration-200 ease-in-out',
                      {
                        'lg:border-primary-300 border-solid shadow-[0_16px_32px_-4px_rgba(12,12,13,0.1)]':
                          isSelected,
                        'relative before:absolute before:top-3 before:h-[calc(100%-24px)] before:left-[-2px] before:w-1 before:rounded-r before:bg-primary-700':
                          isSelected,
                      },
                    )}
                  >
                    <div className='mb-3 cursor-pointer'>
                      <div className='flex items-center justify-between text-neutral-950 mb-1 font-sora text-sm font-semibold'>
                        <h2 className='text-xl font-semibold'>{chapter?.chapterName}</h2>
                        <img src={RedirectIcon} alt='redirect icon' width={20} height={20} />
                      </div>
                      {isImpersonatedUser ? (
                        <span
                          className={`font-normal uppercase px-2 py-[1px] rounded-[24px] text-xs !font-roboto ${
                            chapter?.reportStatus === 'draft'
                              ? 'bg-pink-100 text-pink-800'
                              : 'text-semantic-green-700 bg-semantic-green-100'
                          }`}
                        >
                          {chapter?.reportStatus}
                        </span>
                      ) : null}
                      <p className='text-xs font-poppins font-medium text-neutral-300'>
                        {chapter?.dateRange}
                      </p>
                    </div>
                    <div className='flex flex-col gap-3 p-2 bg-neutral-50 rounded-lg'>
                      <div className='flex items-center text-xs font-medium font-poppins text-neutral-300'>
                        <div className='w-8 h-8 p-2 rounded-full border border-neutral-100 mr-2'>
                          <img src={NotebookIcon} alt='notebook icon' className='w-4 h-4' />
                        </div>
                        <span className='text-neutral-600 mr-1'>Notebook Evaluations:</span>
                        <span className='text-sm font-semibold font-poppins text-neutral-900'>
                          {chapter?.notebookEvaluations}
                        </span>
                        <img src={InfoIconBlue} alt='info icon' width={20} height={20} />
                      </div>

                      {/* <div className='flex items-center text-xs font-medium font-poppins text-neutral-300'>
                        <div className='w-8 h-8 p-2 rounded-full border border-neutral-100 mr-2'>
                          <img src={practiceIcon} alt='practice icon' className='w-4 h-4' />
                        </div>
                        <span className='text-sm text-neutral-600 mr-1'>Poll Evaluations:</span>
                        <span className='text-sm font-semibold font-poppins text-neutral-900'>
                          {chapter.pollEvaluation}
                        </span>
                        <img src={infoIcon} alt='info icon' width={20} height={20} />
                      </div> */}

                      <div className='flex items-center text-xs font-medium font-poppins text-neutral-300'>
                        <div className='w-8 h-8 p-2 rounded-full border border-neutral-100 mr-2'>
                          <img src={PracticeIcon} alt='practice icon' className='w-4 h-4' />
                        </div>
                        <span className='text-sm text-neutral-600 mr-1'>Practice Sheets:</span>
                        <span className='text-sm font-semibold font-poppins text-neutral-900'>
                          {chapter?.practiceSheets}
                        </span>
                        <img src={InfoIconBlue} alt='info icon' width={20} height={20} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className='absolute z-[120]'
                style={{
                  top: infoContent.position.top,
                  left: infoContent.position.left,
                }}
              >
                <InfoCard
                  image={getImageForType(infoContent?.type)}
                  title={infoContent?.title}
                  description={infoContent?.description}
                />
              </div>
            </div>,
            document.body,
          )}
      </div>

      {infoContent && isMobile && (
        <BottomSheet isVisible={bottomSheetVisible} onClose={handleBottomSheetClose}>
          <div className='p-4'>
            <InfoCard
              image={getImageForType(infoContent?.type)}
              title={infoContent?.title}
              description={infoContent?.description}
            />
          </div>
        </BottomSheet>
      )}
    </>
  );
};

export default ChapterCard;
