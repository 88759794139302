import {
  ClassList,
  ClassNotes,
  Classes,
  ForgetPasscode,
  Home,
  Login,
  ParentDashboard,
  ParentDashboardClassOver,
  Practice,
  Register,
  StudentDashBoard,
  TermsAndConditions,
} from 'pages';

import { ADMIN_PATHS, PARENT_PATHS, ROUTES, STUDENT_PATHS } from './routePaths';
import Reports from 'pages/StudentGuardians/Reports';
import QuestionCategory from 'pages/StudentGuardians/Reports/QuestionCategory';
import CategoryOutlet from 'pages/StudentGuardians/Reports/CategoryOutlet';
import CategoryDetails from 'pages/StudentGuardians/Reports/CategoryDetails';

export const PROTECTED_ROUTES = [
  { path: PARENT_PATHS.PARENT_DASHBOARD, element: <ParentDashboard /> },
  { path: PARENT_PATHS.CLASS_OVER, element: <ParentDashboardClassOver /> },
  { path: PARENT_PATHS.ALLCLASSES, element: <Classes /> },
  { path: STUDENT_PATHS.STUDENT_DASHBOARD, element: <StudentDashBoard /> },
  { path: STUDENT_PATHS.STUDENT_CLASSES, element: <Classes /> },
  { path: ROUTES.LIST_OF_CLASSES, element: <ClassList /> },
  { path: STUDENT_PATHS.STUDENT_PRACTICE, element: <Practice /> },
  { path: STUDENT_PATHS.CLASS_NOTES, element: <ClassNotes /> },
  { path: PARENT_PATHS.PRACTICE_SHEET, element: <Practice /> },
  {
    path: PARENT_PATHS.REPORTS,
    element: <Reports />,
    children: [
      {
        path: PARENT_PATHS.QUESTION_CATEGORY,
        element: <QuestionCategory />,
        children: [
          {
            path: PARENT_PATHS.CHAPTER_LEVEL_UNDERSTANDING,
            element: <CategoryOutlet />,
            children: [
              {
                path: PARENT_PATHS.REPORTS_STEP_LEVEL,
                element: <CategoryDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const OPEN_ROUTES = [
  { path: ROUTES.ROOT, element: <Home /> },
  { path: ROUTES.LOGIN, element: <Login /> },
  { path: ROUTES.REGISTER, element: <Register /> },
  { path: ROUTES.FORGET_PASSCODE, element: <ForgetPasscode /> },
  { path: ROUTES.TERMS_AND_CONDITION, element: <TermsAndConditions /> },
];

export const ADMIN_ROUTES = [
  {
    path: ADMIN_PATHS.ADMIN_REPORTS,
    element: <Reports />,
    children: [
      {
        path: ADMIN_PATHS.ADMIN_QUESTION_CATEGORY,
        element: <QuestionCategory />,
        children: [
          {
            path: ADMIN_PATHS.ADMIN_CHAPTER_LEVEL_UNDERSTANDING,
            element: <CategoryOutlet />,
            children: [
              {
                path: ADMIN_PATHS.ADMIN_REPORTS_STEP_LEVEL,
                element: <CategoryDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
];
