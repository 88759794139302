/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogLogs } from '@datadog/browser-logs';
import {
  AddJoinExperienceResponse,
  BrowserType,
  DeviceTestError,
  JoinExperienceParams,
  MediaTestParams,
  TestResult,
} from 'types';
import { getBrowserInfo } from './setupScreenPermissionCheck';

// Browser-specific configuration
const BROWSER_CONFIG = {
  firefox: {
    initDelay: 1000,
    videoCheckAttempts: 3,
    audioCheckInterval: 200,
    maxAudioChecks: 15,
    fftSize: 512,
  },
  safari: {
    initDelay: 500,
    videoCheckAttempts: 2,
    audioCheckInterval: 100,
    maxAudioChecks: 10,
    fftSize: 256,
    sampleRate: 48000,
  },
  default: {
    initDelay: 300,
    videoCheckAttempts: 2,
    audioCheckInterval: 100,
    maxAudioChecks: 10,
    fftSize: 256,
  },
} as const;

// Error factory
const createDeviceError = (
  message: string,
  code: string,
  browserSpecific: boolean = false,
): DeviceTestError => ({
  message,
  code,
  browserSpecific,
});

// Camera test utility
export const testVideoDevice = async ({
  videoTrack,
  studentId,
  classId,
  setCameraWorking,
  setCameraLogTriggered,
  cameraLogTriggered,
  triggerAddJoinExperience,
  previousCameraStatus,
  isVideoEnabled,
}: {
  videoTrack: MediaStreamTrack | null;
  studentId: string | number;
  classId: string | number;
  setCameraWorking: (isWorking: boolean) => void;
  setCameraLogTriggered: (triggered: boolean) => void;
  cameraLogTriggered: boolean;
  triggerAddJoinExperience: (params: JoinExperienceParams) => Promise<AddJoinExperienceResponse>;
  previousCameraStatus: boolean | null;
  isVideoEnabled: boolean;
}): Promise<boolean> => {
  // Skip test and API call if video is intentionally disabled
  if (!isVideoEnabled) {
    setCameraWorking(false);
    return false;
  }

  const browserInfo = getBrowserInfo();

  try {
    if (!videoTrack) {
      throw new Error('No video track available');
    }

    // Initial track validation
    const settings = videoTrack.getSettings();
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { willReadFrequently: true });

    if (!ctx) {
      throw new Error('Failed to get canvas context');
    }

    // Configure video element
    video.autoplay = true;
    video.playsInline = true;
    video.muted = true;
    video.srcObject = new MediaStream([videoTrack]);

    // Create cleanup function
    const cleanup = () => {
      video.srcObject = null;
      video.remove();
      canvas.remove();
    };

    try {
      // Wait for video to be ready with browser-specific delays
      await new Promise<void>((resolve, reject) => {
        const timeout = setTimeout(
          () => {
            cleanup();
            reject(new Error('Video load timeout'));
          },
          browserInfo?.name === 'firefox' ? 5000 : 3000,
        );

        video.onloadedmetadata = async () => {
          clearTimeout(timeout);
          try {
            await video.play();
            // Additional delay for Firefox
            if (browserInfo?.name === 'firefox') {
              await new Promise((res) => setTimeout(res, 1000));
            }
            resolve();
          } catch (error) {
            reject(error);
          }
        };

        video.onerror = () => {
          clearTimeout(timeout);
          reject(new Error('Video load error'));
        };
      });

      // Set canvas dimensions
      canvas.width = video.videoWidth || settings.width || 640;
      canvas.height = video.videoHeight || settings.height || 480;

      // Draw and test frame
      ctx.drawImage(video, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const hasVideoContent = imageData.data.some((pixel) => pixel !== 0);

      // Only trigger API if status has changed
      if (!cameraLogTriggered && previousCameraStatus !== hasVideoContent) {
        setCameraLogTriggered(true);
        datadogLogs.logger.info(`Camera test ${hasVideoContent ? 'passed' : 'failed'}`, {
          studentId,
          classId,
          browser: browserInfo,
          trackSettings: settings,
        });

        await triggerAddJoinExperience({
          classId: classId.toString(),
          JoiningExperinceBody: {
            facedIssue: !hasVideoContent,
            deviceName: 'camera',
          },
        });
      }

      setCameraWorking(hasVideoContent);
      cleanup();
      return hasVideoContent;
    } catch (error) {
      cleanup();
      throw error;
    }
  } catch (error) {
    datadogLogs.logger.error(`Camera test error for ${studentId}`, {
      error,
      classId,
      studentId,
      browser: browserInfo,
    });
    setCameraWorking(false);
    return false;
  }
};

// Audio test utility
export const testAudioDevice = async ({
  track,
  config,
  callbacks,
  isAudioEnabled,
}: MediaTestParams & { isAudioEnabled: boolean }): Promise<TestResult> => {
  // Skip test and API call if audio is intentionally disabled
  if (!isAudioEnabled) {
    callbacks?.onDeviceStatusChange?.(false);
    return {
      success: false,
      deviceInfo: {
        deviceId: '',
        label: '',
        kind: '',
      },
    };
  }

  const { browserInfo } = config;
  const browserConfig =
    BROWSER_CONFIG[browserInfo as keyof typeof BROWSER_CONFIG] || BROWSER_CONFIG.default;

  try {
    if (!track) {
      throw createDeviceError('No audio track available', 'NO_TRACK');
    }

    // Browser-specific initialization
    await new Promise((resolve) => setTimeout(resolve, browserConfig.initDelay));

    const settings = track.getSettings();
    if (browserInfo === 'firefox' && !settings.channelCount) {
      throw createDeviceError(
        'Audio track not properly initialized in Firefox',
        'FIREFOX_AUDIO_INIT_FAILED',
        true,
      );
    }

    // Create audio context with browser-specific settings
    const contextOptions: AudioContextOptions =
      browserInfo === 'safari' ? { sampleRate: browserConfig.audioCheckInterval } : {};

    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)(
      contextOptions,
    );
    const mediaStream = new MediaStream([track]);
    const source = audioContext.createMediaStreamSource(mediaStream);
    const analyser = audioContext.createAnalyser();

    source.connect(analyser);
    analyser.fftSize = browserConfig.fftSize;

    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    return new Promise<TestResult>((resolve) => {
      let checkCount = 0;
      let isWorking = false;

      const checkInterval = setInterval(() => {
        analyser.getByteFrequencyData(dataArray);
        const hasAudioSignal = dataArray.some((value) => value > 0);

        if (hasAudioSignal) {
          isWorking = true;
        }

        if (isWorking || ++checkCount >= browserConfig.maxAudioChecks) {
          clearInterval(checkInterval);
          audioContext.close();

          callbacks?.onDeviceStatusChange?.(isWorking);
          callbacks?.onTestComplete?.(isWorking);

          resolve({
            success: isWorking,
            deviceInfo: {
              deviceId: track.id,
              label: track.label,
              kind: track.kind,
            },
          });
        }
      }, browserConfig.audioCheckInterval);
    });
  } catch (error) {
    const deviceError = error as DeviceTestError;
    return {
      success: false,
      error: deviceError,
    };
  }
};

// Helper function to validate device permissions
export const validateDevicePermissions = async (): Promise<{
  video: boolean;
  audio: boolean;
}> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    stream.getTracks().forEach((track) => track.stop());
    return { video: true, audio: true };
  } catch (error) {
    const err = error as Error;
    return {
      video: !err.message.includes('video'),
      audio: !err.message.includes('audio'),
    };
  }
};

// Helper function to get device capabilities
export const getDeviceCapabilities = (track: MediaStreamTrack): MediaTrackCapabilities => {
  return track.getCapabilities();
};

// Helper function to apply optimal constraints based on browser
export const getOptimalConstraints = (browserInfo: BrowserType): MediaStreamConstraints => {
  const baseConstraints = {
    video: {
      width: { ideal: 1280, max: 1280 },
      height: { ideal: 720, max: 720 },
      frameRate: { ideal: 30, max: 30 },
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
    },
  };

  switch (browserInfo) {
    case 'firefox':
      return {
        video: {
          ...baseConstraints.video,
          frameRate: { ideal: 30 },
        },
        audio: {
          ...baseConstraints.audio,
          autoGainControl: { ideal: true },
          echoCancellation: { ideal: true },
          noiseSuppression: { ideal: true },
        },
      };
    case 'safari':
      return {
        video: {
          ...baseConstraints.video,
          width: { min: 640, ideal: 1280, max: 1280 },
          height: { min: 480, ideal: 720, max: 720 },
        },
        audio: {
          ...baseConstraints.audio,
          sampleRate: { ideal: 48000 },
          channelCount: { ideal: 2 },
        },
      };
    default:
      return baseConstraints;
  }
};
