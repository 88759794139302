import QueryString from 'qs';
import apiSlice from '.';

import {
  IBoards,
  ICurrentTimeResponse,
  IGoogleRequestSchool,
  IGrades,
  IRequestSchool,
  IResponseCities,
  IResponseSchool,
  ISchools,
  ISubjectsResponse,
  IAggregatedAttention,
  IStudentDetails,
  SanityEpicSearchResponse,
} from 'types/common';

const commonApiSlices = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    /* list of Grades */
    getGrades: build.query<IGrades, void>({
      query: () => ({
        url: '/grade/list',
      }),
    }),

    getAggregatedAttention: build.query<
      IAggregatedAttention,
      { classId: number; studentId: number }
    >({
      query: ({ classId, studentId }) => ({
        url: `/in-class/engagement/aggregated-attention?classId=${classId}&studentId=${studentId}`,
        method: 'GET',
      }),
    }),

    getStudentDetailsById: build.query<IStudentDetails, { studentId: number }>({
      query: ({ studentId }) => ({
        url: `/auth/student-guardians/get-student?studentId=${studentId}`,
        method: 'GET',
      }),
    }),

    /* list of Boards */
    getBoards: build.query<IBoards, void>({
      query: () => ({
        url: '/board/list',
      }),
    }),

    /* list of Cities */
    getCities: build.query<IResponseCities, { cityName: string }>({
      query: ({ cityName }) => ({
        url: `/v2/utilities/cities`,
        method: 'GET',
        params: {
          filter: {
            name: cityName,
          },
        },
      }),
    }),

    /* list of schools from the DB */
    getSchools: build.query<ISchools, IRequestSchool>({
      query: ({ schoolCity }) => ({
        url: '/school/list',
        params: {
          cityId: schoolCity,
        },
      }),
    }),

    /* list of schools from the Google API's */
    getSchoolFromGoogle: build.mutation<IResponseSchool, IGoogleRequestSchool>({
      query: (params) => ({
        url: `/school/listFromGoogle`,
        params,
      }),
    }),

    /* Get list of subjects */
    getSubjects: build.query<ISubjectsResponse, void>({
      query: () => ({
        url: '/subject/list',
      }),
    }),

    /* Get Current Time */
    getCurrenTime: build.query<ICurrentTimeResponse, void>({
      query: () => ({
        url: '/utility/getCurrentTime',
      }),
    }),

    /* sanity epic search */
    getSanityEpicSearch: build.query<
      SanityEpicSearchResponse,
      { [key: string]: string | number | string[] | number[] }
    >({
      query: (params) => ({
        url: 'v2/epic-search/sanity?' + QueryString.stringify(params),
      }),
    }),
  }),
});

export const {
  useGetGradesQuery,
  useGetBoardsQuery,
  useLazyGetCitiesQuery,
  useGetSchoolsQuery,
  useGetSchoolFromGoogleMutation,
  useGetSubjectsQuery,
  useGetCurrenTimeQuery,
  useLazyGetAggregatedAttentionQuery,
  useGetStudentDetailsByIdQuery,
  useGetSanityEpicSearchQuery,
} = commonApiSlices;
