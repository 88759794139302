import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChapterWiseData, QuestionCategoryEntity, StepLevelAnswers } from 'types/reports';

interface IReports {
  stepLevelData: StepLevelAnswers[];
  chapterWiseData?: ChapterWiseData[];
  selectedCategories: QuestionCategoryEntity | null;
}

const initialState: IReports = {
  chapterWiseData: undefined,
  stepLevelData: [],
  selectedCategories: null,
};

export const reportsData = createSlice({
  name: 'reportsData',
  initialState,
  reducers: {
    setChapterWiseData: (state, action: PayloadAction<ChapterWiseData[]>) => {
      state.chapterWiseData = action.payload;
    },
    setChapterWiseStepLevelData: (state, action: PayloadAction<StepLevelAnswers[]>) => {
      state.stepLevelData = action.payload;
    },
    setSelectedCategories: (state, action: PayloadAction<QuestionCategoryEntity>) => {
      state.selectedCategories = action.payload;
    },
  },
  selectors: {
    selectChapterWiseData: (state) => state?.chapterWiseData,
    selectChapterWiseStepLevelData: (state) => state?.stepLevelData,
    selectSelectedCategories: (state) => state?.selectedCategories,
  },
});

export const { setChapterWiseData, setChapterWiseStepLevelData, setSelectedCategories } =
  reportsData.actions;

export const { selectChapterWiseData, selectChapterWiseStepLevelData, selectSelectedCategories } =
  reportsData.selectors;
