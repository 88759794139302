import React, { useState } from 'react';
import { DashboardNav } from 'components/screens';
import { USER_TYPE } from 'configs';
import SidebarMenu from './MenuPage/SidebarMenu';
import { ISiblingParentListResponse } from 'types';
import { Footer } from 'components/common';

interface DashboardLayoutProps {
  children: React.ReactNode;
  studentId?: string;
  parentSiblingsData?: ISiblingParentListResponse;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  studentId,
  parentSiblingsData,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className='min-h-screen bg-neutral-25 bg-dotted-pattern'>
      <DashboardNav type={USER_TYPE?.GUARDIAN} parentSiblingsData={parentSiblingsData} />
      <div className='relative'>
        <SidebarMenu
          studentId={studentId}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        <div
          className={`transition-all duration-300 ease-in-out lg:pb-20 ${
            isCollapsed ? 'lg:ml-16' : 'lg:ml-96'
          }`}
        >
          {children}
        </div>
      </div>
      <Footer classname='lg:fixed lg:bottom-0 lg:z-50' />
    </div>
  );
};

export default DashboardLayout;
