import React from 'react';
import classNames from 'classnames';
import { CategoryCardProps } from 'types/reports';
import Tag from '../Tag';
import { getStatusClasses } from 'configs/studentReport';
import { BulletIcon, RedirectIcon } from 'assets/svg';

const CategoryCard: React.FC<CategoryCardProps> = ({
  category,
  onClick,
  className,
  selected = false,
  evaluationId,
}) => {
  return (
    <div
      className={classNames(
        'relative bg-white rounded-[12px] p-[12px] cursor-pointer before:absolute before:top-[12px] before:h-[calc(100%-24px)] before:left-0 before:rounded-tr-[12px] before:rounded-br-[12px] before:w-[4px]',
        className,
        getStatusClasses(evaluationId),
        {
          'border border-primary-500': selected,
        },
      )}
      onClick={onClick}
    >
      <div className='mb-[12px]'>
        <div className='flex items-center justify-between mb-[4px]'>
          <h2 className='text-neutral-300 font-poppins font-medium text-xs'>
            Category {category?.categoryNumber}
          </h2>
          <img src={RedirectIcon} alt='redirect icon' width={20} height={20} />
        </div>
        <div className='flex items-center justify-between gap-[4px] text-neutral-900 text-[14px]'>
          <h3 className='text-neutral-950 max-w-full font-sora font-semibold text-sm'>
            {category?.title}
          </h3>
        </div>
      </div>
      <div className='flex flex-col gap-[16px] py-[12px] px-[8px] bg-neutral-50 rounded-[8px]'>
        {/* Total Questions */}
        <div className='flex items-center justify-start gap-[8px]'>
          <img src={BulletIcon} alt='bullet icon' width={18} height={18} />
          <span className='text-neutral-700 font-poppins font-semibold text-base'>
            Total Questions:
          </span>
          <span className='text-neutral-900 font-poppins font-medium text-base'>
            {category?.totalQuestions}
          </span>
        </div>

        {/* Correctly Answered */}
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-[8px]'>
            <img src={BulletIcon} alt='bullet icon' width={18} height={18} />
            <span className='text-neutral-500 font-poppins font-medium text-sm'>
              Correct Answers:
            </span>
          </div>
          <div className='flex items-center gap-[4px]'>
            <Tag
              text={`${category?.questionsAnsweredCorrectly} (${(
                (category?.questionsAnsweredCorrectly / category?.totalQuestions) *
                100
              ).toFixed(0)}%)`}
              className='success'
            />
          </div>
        </div>

        {/* Student Could Not Solve */}
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-[8px]'>
            <img src={BulletIcon} alt='bullet icon' width={18} height={18} />
            <span className='text-neutral-500 font-poppins font-medium text-sm'>
              Not Attempted:
            </span>
          </div>
          <div className='flex items-center gap-[4px]'>
            <Tag
              text={`${category?.questionNotAttempted} (${(
                (category?.questionNotAttempted / category?.totalQuestions) *
                100
              ).toFixed(0)}%)`}
              className='brown'
            />
          </div>
        </div>

        {/* Incorrectly Answered */}
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-[8px]'>
            <img src={BulletIcon} alt='bullet icon' width={18} height={18} />
            <span className='text-neutral-500 font-poppins font-medium text-sm'>
              Incorrect Answers:
            </span>
          </div>
          <div className='flex items-center gap-[4px]'>
            <Tag
              text={`${category?.incorrectAnswer} (${(
                (category?.incorrectAnswer / category?.totalQuestions) *
                100
              ).toFixed(0)}%)`}
              className='error'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
