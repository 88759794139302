interface CameraUtilsConfig {
  onCameraError: (show: boolean) => void;
  allRequestedTracks: React.MutableRefObject<MediaStreamTrack[]>;
  self?: {
    enableVideo: () => Promise<void>;
  };
}

/**
 * Sets up camera error tracking by intercepting getUserMedia calls
 * Only tracks video devices and ignores audio
 */
export const setupCameraErrorTracking = ({
  onCameraError,
  allRequestedTracks,
}: CameraUtilsConfig) => {
  // Store the original getUserMedia function
  const originalGetUserMedia = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);

  // Override getUserMedia to add camera error tracking
  navigator.mediaDevices.getUserMedia = async (
    constraints: MediaStreamConstraints,
  ): Promise<MediaStream> => {
    try {
      // Only proceed with camera tracking if video constraints are present
      if (!constraints.video) {
        return await originalGetUserMedia(constraints);
      }

      const stream = await originalGetUserMedia(constraints);

      // Only track video tracks, ignore audio tracks
      const videoTracks = stream.getVideoTracks();
      videoTracks.forEach((track: MediaStreamTrack) => {
        allRequestedTracks.current.push(track);

        // Remove track from tracking when it ends
        track.onended = () => {
          const index = allRequestedTracks.current.indexOf(track);
          if (index > -1) {
            allRequestedTracks.current.splice(index, 1);
          }
          // Check camera state when a track ends
          checkCameraState();
        };
      });

      // Check if we have any active video tracks
      checkCameraState();
      return stream;
    } catch (error: unknown) {
      // Only handle camera-specific errors
      if (error instanceof Error) {
        console.log('Camera error:', error);
        if (
          error.name === 'NotReadableError' ||
          error.message.includes('Could not start video source') ||
          (constraints.video &&
            (error.name === 'NotFoundError' ||
              error.name === 'NotAllowedError' ||
              error.name === 'OverconstrainedError'))
        ) {
          onCameraError(true);
        }
      }
      return Promise.reject(error);
    }
  };

  // Helper function to check camera state
  const checkCameraState = () => {
    const hasActiveVideoTracks = allRequestedTracks.current.some(
      (track: MediaStreamTrack) =>
        track.kind === 'video' && track.readyState === 'live' && track.enabled,
    );
    onCameraError(!hasActiveVideoTracks);
  };

  return () => {
    // Restore original getUserMedia
    navigator.mediaDevices.getUserMedia = originalGetUserMedia;

    // Stop and clear only video tracks
    allRequestedTracks.current
      .filter((track) => track.kind === 'video')
      .forEach((track: MediaStreamTrack) => track.stop());

    allRequestedTracks.current = allRequestedTracks.current.filter(
      (track) => track.kind !== 'video',
    );
  };
};

/**
 * Handles camera retry attempts
 * Only attempts to reinitialize video, not audio
 */
export const handleCameraRetry = async ({
  onCameraError,
}: Pick<CameraUtilsConfig, 'onCameraError' | 'self'>): Promise<void> => {
  try {
    // Only request video permissions
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false, // Explicitly exclude audio
    });
    stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    onCameraError(false);
  } catch (err) {
    onCameraError(true);
  }
};
